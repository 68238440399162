(function () {
    'use strict';
    angular
        .module('alpha.common.services.authentication', [
            'AlphaApi',
            'ui.bootstrap',
            'alpha.utils.LogOutAction'
        ])
        .factory('AuthenticationService', AuthenticationService);

    AuthenticationService.$inject = [
        '$q',
        'SecurityInterface',
        '$templateRequest',
        '$uibModal',
        'LogOutAction'
    ];

    function AuthenticationService(
        $q,
        SecurityInterface,
        $templateRequest,
        $uibModal
    ) {
        return {
            authenticateUser : authenticateUser,
            checkUserAuthority: checkUserAuthority,
            openAuthenticatedModal : openAuthenticatedModal,
            isAuthenticationExternal: isAuthenticationExternal,
            isAuthenticationSSO: isAuthenticationSSO
        };

        function authenticateUser(){
            var deferred = $q.defer();
            SecurityInterface.getAuthorizedUser(function success(response){
                deferred.resolve(response);
            }, function error(response){
                deferred.reject(response.data.errorMessage);
            });
            return deferred.promise;
        }
        function openAuthenticatedModal(options) {
            var deferred = $q.defer();
            authenticateUser()
                .then(function () {
                    deferred.resolve($uibModal.open(options).result);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function checkUserAuthority(client, authority){
            var deferred = $q.defer();
            SecurityInterface.hasAuthority(
                client,
                authority,
                function success(authority) {
                    deferred.resolve(authority);
                }, function error(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function isAuthenticationExternal() {
            var deferred = $q.defer();
            authenticateUser()
                .then(function(user) {
                    deferred.resolve(user.userType!=="ExternalUser");
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function isAuthenticationSSO() {
            var deferred = $q.defer();
            authenticateUser()
                .then(function(user) {
                    deferred.resolve(user.userType==="SSO");
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    }
})();
