(function () {
    'use strict';

    angular
        .module('alpha.common.services.security', [
            'AlphaApi',
            'UserPreferences'
        ])
        .factory('SecurityService', SecurityService)
        .constant('TOOL_AUTHORITIES', [
        'DataModelDesignAuthority',
        'BusinessRulesWorkbenchAuthority',
        'BusinessObjectWorkbenchAuthority',
        'UIWorkbenchAuthority'
        ])
        .constant('USER_AUTH_LIST',{
        ShowAdministrationSetting: {
            'authority':['ShowAdministrationSetting'],
            'active': false,
            'disabled': true
        },
        BusinessIntelligenceAuthority: {
            'authority':['BusinessIntelligenceAuthority'],
            'active': false,
            'disabled': true
        },
        LookupMaintenanceAuthority: {
            'authority':['LookupMaintenanceAuthority'],
            'active': false,
            'disabled': true
        },
        ToolsAuthories:  {
            'authority':[
                'DataModelDesignAuthority',
                'BusinessRulesWorkbenchAuthority',
                'BusinessObjectWorkbenchAuthority',
                'UIWorkbenchAuthority'
            ],
            'active': false,
            'disabled': true
        },
        SecurityAssignmentAuthority: {
            'authority':['SecurityAssignmentAuthority'],
            'active': false,
            'disabled': true
        },
        RecordMenuAuthority:{
            'authority':['RecordMenuAuthority'],
            'active': false,
            'disabled': true
        },
        AddRecordAuthority: {
            'authority':['AddRecordAuthority'],
            'active': false,
            'disabled': true
        },
        RecentRecordAuthority: {
            'authority':['RecentRecordAuthority'],
            'active': false,
            'disabled': true
        },
        DataGovernanceAuthority: {
            'authority':['DataGovernanceAuthority'],
            'active': false
        },
        AnalyticsAuthority : {
            'authority':['AnalyticsAuthority'],
            'active': false,
            'disabled': true
        },
        GlobalSearchAuthority: {
            'authority':['GlobalSearchAuthority'],
            'active': false,
            'disabled': true
        },
        MapSearchAuthority: {
            'authority':['MapSearchAuthority'],
            'active': false,
            'disabled': true
        },
        AdvanceQueryAuthority: {
            'authority':['AdvanceQueryAuthority'],
            'active': false,
            'disabled': true
        },
        ReportAuthority: {
            'authority':['ReportAuthority'],
            'active': false,
            'disabled': true
        },
        EditReportAuthority: {
            'authority':['EditReportAuthority'],
            'active': false,
            'disabled': true
        },
        DeleteReportAuthority:  {
            'authority':['DeleteReportAuthority'],
            'active': false,
            'disabled': true
        },
        DataDiscoveryAuthority:  {
            'authority':['DataDiscoveryAuthority'],
            'active': false,
            'disabled': true
        },
        CreateEditUsersDashboards: {
            'authority':['CreateEditUsersDashboards'],
            'active': false,
            'disabled': true
        },
        CreateEditAnyPublicDashboards: {
            'authority':['CreateEditAnyPublicDashboards'],
            'active': false,
            'disabled': true
        },
        CreateEditAnyPublicAdvanceQuery: {
            'authority':['CreateEditAnyPublicAdvanceQuery'],
            'active': false,
            'disabled': true
        },
        ClientDashboardFavorites: {
            'authority':['ClientDashboardFavorites'],
            'active': false,
            'disabled': true
        },
        UserDashboardFavorites: {
            'authority':['UserDashboardFavorites'],
            'active': false,
            'disabled': true
        },
        DeleteUsersDashboards: {
            'authority':['DeleteUsersDashboards'],
            'active': false,
            'disabled': true
        },
        DeleteAnyPublicDashboards: {
            'authority':['DeleteAnyPublicDashboards'],
            'active': false,
            'disabled': true
        },
        DeleteAnyPublicAdvanceQuery: {
            'authority':['DeleteAnyPublicAdvanceQuery'],
            'active': false,
            'disabled': true
        },
        CreatePublicReportAuthority: {
            'authority': ['CreatePublicReportAuthority'],
            'active': false,
            'disabled': true
        },
        SpreadSheetUploadAuthority: {
            'authority': ['SpreadSheetUploadAuthority'],
            'active': false,
            'disabled': true
        },
        ViewDataLoadsAuthority: {
            'authority': ['ViewDataLoadsAuthority'],
            'active': false,
            'disabled': true
        },
        RateSchemeAuthority:{
            'authority': ['RateSchemeAuthority'],
            'active': false,
            'disabled': true
        },
        FormLetterAuthority:{
            'authority': ['FormLetterAuthority'],
            'active': false,
            'disabled': true
        },
        QuickSearchAuthority:{
            'authority': ['QuickSearchAuthority'],
            'active': false,
            'disabled': true
        },
        DisableTopMenusAuthority:{
            'authority': ['DisableTopMenusAuthority'],
            'active': false,
            'disabled': true
        },
        RulePLSQLAccess:{
            'authority': ['RulePLSQLAccess'],
            'active': false,
            'disabled': true
        },
        WorkFlowAutomationAuthority:{
            'authority': ['WorkFlowAutomationAuthority'],
            'active': false,
            'disabled': true
        },
        ResetPasswordsOnlyAuthority:{
            'authority': ['ResetPasswordsOnlyAuthority'],
            'active': false,
            'disabled': true
        },
        AllClientUserAuthority:{
            'authority': ['AllClientUserAuthority'],
            'active': false,
            'disabled': true
        },
        ByPassRuleAuthority:{
            'authority': ['ByPassRuleAuthority'],
            'active': false,
            'disabled': true
        },
        EditRecordCurrencyAuthority:{
            'authority': ['EditRecordCurrencyAuthority'],
            'active': false,
            'disabled': true
        },
        DataScienceAuthority:{
            'authority': ['DataScienceAuthority'],
            'active': false,
            'disabled': true
        },
        UserClientInfoAuthority:{
            'authority': ['UserClientInfoAuthority'],
            'active': false,
            'disabled': true
        }
    });

    SecurityService.$inject = [
        'SecurityInterface',
        'UserPreferences',
        '$q',
        'TOOL_AUTHORITIES',
        'USER_AUTH_LIST',
        'SearchInterface'
    ];

    function SecurityService(
        SecurityInterface,
        UserPreferences,
        $q,
        TOOL_AUTHORITIES,
        USER_AUTH_LIST,
        SearchInterface
    ) {
        var _fieldTypeSecurityData = {},
            _recordTypeSecurityData;

        return {
            loadRecordTypeSecurity: loadRecordTypeSecurity,
            loadFieldTypeSecurity: loadFieldTypeSecurity,
            fieldCanBeRead: fieldCanBeRead,
            fieldCanBeUpdated: fieldCanBeUpdated,
            fieldHistoryCanBeRead: fieldHistoryCanBeRead,
            recordCanBeCreated: recordCanBeCreated,
            recordCanBeRead: recordCanBeRead,
            recordCanBeUpdated: recordCanBeUpdated,
            recordCanBeDeleted: recordCanBeDeleted,
            getUserAuthorities: getUserAuthorities,
            getCurrentUserAuthorities: getCurrentUserAuthorities,
            hasToolAuthority: hasToolAuthority,
            hasAdminAuthority: hasAdminAuthority,
            hasRowLevelSecurity: hasRowLevelSecurity,
            hasRowLevelBypass: hasRowLevelBypass,
            userHasAuthority:userHasAuthority,
            getUserAuthoritiesList:getUserAuthoritiesList,
            getToolAuthorties:getToolAuthorties
        };

        // Public methods

        /**
         * Loads record type security for the current user so the
         * record type permission methods can be used.
         *
         * @method loadRecordTypeSecurity
         *
         * @returns {Object} A promise to be resolved or rejected with an error
         */
        function loadRecordTypeSecurity(recordType, objectId) {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId) {
                    SecurityInterface.getUserRecordTypeMetaDataFromCache(
                        clientId,
                        recordType,
                        objectId,
                        function success(response) {
                            _recordTypeSecurityData = response;
                            deferred.resolve();
                        }, function error(response) {
                            deferred.reject(_.get(response, 'data.errorMessage'));
                        }
                    );
                })
                .catch(function(response) {
                    deferred.reject(response);
                });
           return deferred.promise;
        }
        /**
         * Loads field type security for the current user so the
         * field type permission methods can be used without any
         * metadata being provided. Provided metadata will always
         * be prioritized over what is cached.
         *
         * @method loadFieldTypeSecurity
         *
         * @param {String|String[]} recordType Service name(s) of the record type(s) to load
         *
         * @returns {Object} A promise to be resolved or rejected with an error
         */
        function loadFieldTypeSecurity(recordType) {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId) {
                    if (_.isArray(recordType)) {
                        SecurityInterface.getUserFieldTypeMetaDataForRecordTypesFromCache(
                            clientId,
                            recordType,
                            function success(response) {
                                angular.extend(_fieldTypeSecurityData, response);
                                deferred.resolve();
                            },
                            function error(response) {
                                deferred.reject(_.get(response, 'data.errorMessage'));
                            }
                        )
                    } else {
                        SecurityInterface.getUserFieldTypeMetaDataFromCache(
                            clientId,
                            recordType,
                            function success(response) {
                                _fieldTypeSecurityData[recordType] = response;
                                deferred.resolve();
                            },
                            function error(response) {
                                deferred.reject(_.get(response, 'data.errorMessage'));
                            }
                        );
                    }
                })
                .catch(function(response) {
                    deferred.reject(response);
                });
           return deferred.promise;
        }
        /**
         * Checks whether any field(s) can be read by a user.
         *
         * @method fieldCanBeRead
         *
         * @param {String} recordType Service name of the record type of the field(s); can be null if metadata is provided
         * @param {String|String[]} fieldType Service name(s) of the field type(s) to check
         * @param {Object} [metadata] Metadata from the record the field type belongs to
         *
         * @returns {Boolean} Whether any of the field(s) can be read
         */
        function fieldCanBeRead(recordType, fieldType, metadata) {
            metadata = metadata || _fieldTypeSecurityData[recordType];
            if (metadata) {
                return _.isString(fieldType) ? _checkSingleField(fieldType) : _checkMultipleFields();
            } else {
                return false;
            }
            function _checkSingleField(fieldTypeToCheck) {
                return metadata[fieldTypeToCheck] ? metadata[fieldTypeToCheck].Unavailable !== null : true;
            }
            function _checkMultipleFields() {
                var canBeRead = false;
                _.forEach(fieldType, function(fieldTypeToCheck) {
                    canBeRead = _checkSingleField(fieldTypeToCheck);
                    return !canBeRead;
                });
                return canBeRead;
            }
        }
        /**
         * Checks whether a field can be updated by a user.
         *
         * @method fieldCanBeUpdated
         *
         * @param {String} recordType Service name of the record type of the field; can be null if metadata is provided
         * @param {String} fieldType Service name of the field type to check
         * @param {Object} [metadata] Metadata from the record the field type belongs to
         *
         * @returns {Boolean} Whether the field can be updated
         */
        function fieldCanBeUpdated(recordType, fieldType, metadata) {
            metadata = metadata || _fieldTypeSecurityData[recordType];
            if (metadata) {
                return metadata[fieldType] ? metadata[fieldType].ReadOnly !== null : true;
            } else {
                return true;
            }
        }
        /**
         * Checks whether the history of a field can be read by a user.
         *
         * @method fieldHistoryCanBeRead
         *
         * @param {String} recordType Service name of the record type of the field; can be null if metadata is provided
         * @param {String} fieldType Service name of the field type to check
         * @param {Object} [metadata] Metadata from the record the field type belongs to
         *
         * @returns {Boolean} Whether the history can be read
         */
        function fieldHistoryCanBeRead(recordType, fieldType, metadata) {
            metadata = metadata || _fieldTypeSecurityData[recordType];
            if (metadata) {
                return metadata[fieldType] ? metadata[fieldType].NoHistory !== null : true;
            } else {
                return false;
            }
        }
        /**
         * Checks whether a record can be created by a user.
         * Invoke loadRecordTypeSecurity() before using this.
         *
         * @method recordCanBeCreated
         *
         * @param {String} recordType Service name of the record type to check
         *
         * @returns {Boolean} Whether the record can be created
         */
        function recordCanBeCreated(recordType) {
            if (_recordTypeSecurityData) {
                return _recordTypeSecurityData[recordType] ? _recordTypeSecurityData[recordType].NoCreate !== null : true;
            } else {
                return false;
            }
        }
        /**
         * Checks whether a record can be read by a user.
         * Invoke loadRecordTypeSecurity() before using this.
         *
         * @method recordCanBeRead
         *
         * @param {String} recordType Service name of the record type to check
         *
         * @returns {Boolean} Whether the record can be read
         */
        function recordCanBeRead(recordType) {
            if (_recordTypeSecurityData) {
                return _recordTypeSecurityData[recordType] ? _recordTypeSecurityData[recordType].NoRead !== null : true;
            } else {
                return false;
            }
        }
        /**
         * Checks whether a record can be updated by a user.
         * Invoke loadRecordTypeSecurity() before using this.
         *
         * @method recordCanBeUpdated
         *
         * @param {String} recordType Service name of the record type to check
         * @param {Object} [metadata] Metadata from the record containing security properties
         *
         * @returns {Boolean} Whether the record can be updated
         */
        function recordCanBeUpdated(recordType, metadata) {
            if (metadata && metadata._allFields && metadata._allFields.ReadOnly === null) {
                return false;
            } else if (_recordTypeSecurityData) {
                return _recordTypeSecurityData[recordType] ? _recordTypeSecurityData[recordType].NoUpdate !== null : true;
            } else {
                return true;
            }
        }
        /**
         * Checks whether a record can be deleted by a user.
         * Invoke loadRecordTypeSecurity() before using this.
         *
         * @method recordCanBeDeleted
         *
         * @param {String} recordType Service name of the record type to check
         *
         * @returns {Boolean} Whether the record can be deleted
         */
        function recordCanBeDeleted(recordType) {
            if (_recordTypeSecurityData) {
                return _recordTypeSecurityData[recordType] ? _recordTypeSecurityData[recordType].NoDelete !== null : true;
            } else {
                return false;
            }
        }
        function getUserAuthorities(){
            var deferred = $q.defer();
            SecurityInterface.getAuthorizedUserFromCache(
                function success(user){
                    deferred.resolve(user.authorities);
                }, function error(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function getCurrentUserAuthorities() {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId) {
                    SecurityInterface.getAuthorizedUserFromCache(
                        function success(response) {
                            var clientAuthorities = _.chain(response.authorities)
                                .filter({clientId: clientId})
                                .map('authority')
                                .value();
                            deferred.resolve(clientAuthorities);
                        }, function error(response) {
                            deferred.reject(response.data.errorMessage);
                        });
                    })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function hasToolAuthority(client, currentAuthorities){
            var toolAuthority = _.chain(currentAuthorities)
                .filter(function (authority) {
                    return authority.clientId === client && _.includes(TOOL_AUTHORITIES, authority.authority);
                })
                .map('authority')
                .value();
            return toolAuthority.length === TOOL_AUTHORITIES.length;
        }
        function hasAdminAuthority(client, currentAuthorities){
            var adminAuthority = _.find(currentAuthorities, {clientId: client, authority : 'ShowAdministrationSetting'});
            return _.isObject(adminAuthority);
        }
        function hasRowLevelBypass(client, currentAuthorities){
            var rowLevelSecurity = _.find(currentAuthorities, {clientId: client, authority : 'BypassRowLevelSecurity'});
            return _.isObject(rowLevelSecurity);
        }

        function hasRowLevelSecurity(){
            var deferred = $q.defer(),
                searchRequest = {
                    recordTypeTree: {
                        _fieldType: []
                    },
                    fieldList: [{
                        recordTypeId: '_fieldType', fieldName: 'id'
                    }],
                    termGroupOperator: 'AND',
                    terms: [{
                        field: {
                            recordTypeId: '_fieldType',
                            fieldName: 'lookupSecurityEnabled'
                        },
                        operator: 'EQUALS',
                        value: true
                    }]
                };
            UserPreferences.getClientId()
                .then(function(clientId){
                    SearchInterface.performSearchFromPost(
                        clientId,
                        searchRequest,
                        'oracle',
                        false,
                        function success(data) {
                            deferred.resolve(!!data.searchResults.searchResults.length);
                        }, function error(reason) {
                            deferred.reject(reason);
                        }
                    );
                })
                .catch(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function userHasAuthority(client,currentAuthorities,authName) {
            var returnValue;
            var userAuthoritiesArray = ["UserClientInfoAuthority"];
            if(authName === 'ToolsAuthories'){
                returnValue = hasToolAuthority(client,currentAuthorities)
            } else if(userAuthoritiesArray.includes(authName)) {
                returnValue = _.isObject(_.find(currentAuthorities, {authority: authName}));
            } else {
                returnValue = _.isObject(_.find(currentAuthorities, {clientId: client, authority: authName}));
            }
            return returnValue;
        }
        function getUserAuthoritiesList(){
            return USER_AUTH_LIST;
        }
        function getToolAuthorties(){
            return TOOL_AUTHORITIES;
        }
    }
})();
