(function() {
    'use strict';

    /**
     * This service is to be used with alpha.common.directives.alphaTabManager. It
     * provides full control over the closest set of tabs from any component in any
     * window, provided that window is a descendant of the one containing the tabs.
     */

    angular
        .module('alpha.common.services.alphaTabManager', [])
        .factory('AlphaTabManagerService', AlphaTabManagerService);

    AlphaTabManagerService.$inject = ['$window'];

    function AlphaTabManagerService($window) {
        return {
            addTab: addTab,
            updateTitle: updateTitle,
            updateTitleSecurely: updateTitleSecurely,
            getTabId: getTabId,
            selectTab: selectTab,
            updateDetails: updateDetails,
            setDirtyState: setDirtyState,
            setLoadingState: setLoadingState,
            showLoadingIndicator: showLoadingIndicator,
            hideLoadingIndicator: hideLoadingIndicator,
            updateTabUrl: updateTabUrl,
            closeTab: closeTab
        };

        // Public methods

        /**
         * Opens a new tab on the nearest parent with an AlphaTabManager element.
         * The title will be put in local storage. Do not set the title to any
         * value that contains secure information.
         *
         * @method addTab
         *
         * @param {String} id Unique ID of the tab
         * @param {String} title Display name of the tab
         * @param {String} url URL of the iframe that will be opened
         * @param {String} icon classname for the tab's icons
          * @param {String} name Name for the tab
         */
        function addTab(id, title, url, icon,name ) {
            var eventService = _getEventService();
            if (_.isObject(eventService)) { // open in jqx tab within application
                eventService.publish('AlphaTabManager', 'addTab', {
                    id: id,
                    title: title,
                    url: url,
                    icon: icon || 'icon-ic_claim_doc',
                    name: name
                });
            } else { // open in new browser tab
                var counter = new Date().getTime();
                var windowName = name + counter;
                window.open(url, windowName);
            }
        }
        /**
         * Updates the title of an open tab. This title will be put in
         * local storage. Do not use this for any secure information such
         * as a field value.
         *
         * @method updateTitle
         *
         * @param {String} id Unique ID of the tab
         * @param {String} title New display name of the tab
         * @param {Boolean} clipLeft Whether to truncate the left side
         * @param {String} icon classname for the tab's icons
         * @param {String} cssClass for the tab styling
         * @param {String} recordId Record Id
         */
        function updateTitle(id, title, clipLeft, icon, cssClass, recordId) {
            var eventService;
            if (!_.isEmpty(id)) {
                eventService = _getEventService();
                if (_.isObject(eventService)) {
                    eventService.publish('AlphaTabManager', 'updateTitle', {
                        id: id,
                        title: title,
                        clipLeft: clipLeft,
                        icon: icon,
                        cssClass:cssClass,
                        recordId: recordId
                    });
                }
            }
        }
        /**
         * Updates the title of an open tab in a way that prevents it from
         * being put in local storage. Use this for any titles that contain
         * field values or other secure data. When a user logs out and back
         * in again, the title will revert to whatever was most recently set
         * with updateTitle() or specified when adding the tab.
         *
         * @method updateTitleSecurely
         *
         * @param {String} id Unique ID of the tab
         * @param {String} title New display name of the tab
         * @param {Boolean} [clipLeft] Whether to truncate the left side
         * @param {String} [recordId] Record Id
         */
        function updateTitleSecurely(id, title, clipLeft, recordId) {
            var eventService;
            if (!_.isEmpty(id)) {
                eventService = _getEventService();
                if (_.isObject(eventService)) {
                    eventService.publish('AlphaTabManager', 'updateTitleSecurely', {
                        id: id,
                        title: title,
                        clipLeft: clipLeft,
                        recordId: recordId
                    });
                }
            }
        }

        /**
         * Updates the source url for the tab iframe and puts it into local storage
         * @param {String} id Unique ID of the tab
         * @param {String} url New url for the tab iframe
         */
        function updateTabUrl(id, url){
            var eventService;
            if (!_.isEmpty(id)) {
                eventService = _getEventService();
                if (_.isObject(eventService)) {
                    eventService.publish('AlphaTabManager', 'updateTabUrl', {
                        id: id,
                        url: url
                    });
                }
            }
        }
        /**
         * Updates the details of an open tab.
         *
         * @method updateDetails
         *
         * @param {String} id Unique ID of the tab
         * @param {Object[]} details New details to load
         * @param {String} details.label The label to display for the detail
         * @param {String} details.value The value to display for the detail
         * @param {Number} details.ordinal The order to display the detail in
         */
        function updateDetails(id, details) {
            var eventService;
            if (!_.isEmpty(id)) {
                eventService = _getEventService();
                if (_.isObject(eventService)) {
                    eventService.publish('AlphaTabManager', 'updateDetails', {
                        id: id,
                        details: details
                    });
                }
            }
        }
        /**
         * Returns the ID of the tab for the window the service is loaded in.
         *
         * @method getTabId
         *
         * @returns {String} id Unique ID of the tab, or undefined if it can't be determined
         */
        function getTabId() {
            var id;
            try {
                if (window.frameElement !== null) {
                    id = window.frameElement.id;
                }
            } catch(e) {} // Prevent exceptions when running app in an iframe
            return id;
        }
        /**
         * Will set focus on tab with the index handed in
         *
         * @method selectTab
         *
         * @param {String} id Unique ID of the tab
         * @returns None
         */
        function selectTab(id) {
            var eventService = _getEventService();
            if (_.isObject(eventService)) {
                eventService.publish('AlphaTabManager', 'selectTab', id);
            }
        }
        /**
         * Updates the tab's dirty state.
         *
         * @method setDirtyState
         *
         * @param {String} id Unique ID of the tab
         * @param {Boolean} isDirty Whether to set the dirty state
         */
        function setDirtyState(id, isDirty) {
            var eventService;
            if (!_.isEmpty(id)) {
                eventService = _getEventService();
                if (_.isObject(eventService)) {
                    eventService.publish('AlphaTabManager', 'setDirtyState', {
                        id: id,
                        isDirty: isDirty
                    });
                }
            }
        }
        /**
         * Updates the tab's loading state.
         *
         * @method setLoadingState
         *
         * @param {String} id Unique ID of the tab
         * @param {Boolean} isLoading Whether the tab is loading or not
         */
        function setLoadingState(id, isLoading) {
            var eventService;
            if (!_.isEmpty(id)) {
                eventService = _getEventService();
                if (_.isObject(eventService)) {
                    eventService.publish('AlphaTabManager', 'setLoadingState', {
                        id: id,
                        isLoading: isLoading
                    });
                }
            }
        }
        /**
         * Shows the tab's loading indicator.
         *
         * @method showLoadingIndicator
         *
         * @param {String} id Unique ID of the tab
         */
        function showLoadingIndicator(id) {
            var eventService;
            if (!_.isEmpty(id)) {
                eventService = _getEventService();
                if (_.isObject(eventService)) {
                    eventService.publish('AlphaTabManager', 'showLoadingIndicator', id);
                }
            }
        }
        /**
         * Hides the tab's loading indicator.
         *
         * @method hideLoadingIndicator
         *
         * @param {String} id Unique ID of the tab
         */
        function hideLoadingIndicator(id) {
            var eventService;
            if (!_.isEmpty(id)) {
                eventService = _getEventService();
                if (_.isObject(eventService)) {
                    eventService.publish('AlphaTabManager', 'hideLoadingIndicator', id);
                }
            }
        }
        /**
         * Closes a tab.
         *
         * @method closeTab
         *
         * @param {String} id Unique ID of the tab
         */
        function closeTab(id) {
            var eventService;
            if (!_.isEmpty(id)) {
                eventService = _getEventService();
                if (_.isObject(eventService)) {
                    eventService.publish('AlphaTabManager', 'closeTab', id);
                }
            }
        }

        // Private functions

        function _getEventService() {
            var eventService;
            if ($window.AlphaTabManagerPresent === true) {
                eventService = $window.AlphaEventService;
            } else {
                _getNextParent($window);
            }
            return eventService;
            function _getNextParent(obj) {
                if (_.isObject(obj.parent) && obj !== obj.parent) {
                    if (obj.parent.AlphaTabManagerPresent === true) {
                        eventService = obj.parent.AlphaEventService;
                    } else {
                        _getNextParent(obj.parent);
                    }
                }
            }
        }
    }
})();
