(function() {
    'use strict';
    angular
        .module('alpha.login.Login')
        .factory('LoginService', LoginService);

    LoginService.$inject = [
        'UserPreferencesInterface',
        'UserDetailsInterface',
        'DataModelDesignInterface',
        'I18nUtil',
        '$cookies',
        '$http',
        '$q',
        'UserPreferences',
        'AlphaApiUtils',
        'COOKIE_ACCEPT_MAX_DAYS'
    ];

    function LoginService(
        UserPreferencesInterface,
        UserDetailsInterface,
        DataModelDesignInterface,
        I18nUtil,
        $cookies,
        $http,
        $q,
        UserPreferences,
        AlphaApiUtils,
        COOKIE_ACCEPT_MAX_DAYS
    ) {
        return {
            setDefaultName: setDefaultName,
            getDefaultName: getDefaultName,
            setTOTPMessage: setTOTPMessage,
            getTOTPMessage: getTOTPMessage,
            getDefaultRememberMe: getDefaultRememberMe,
            setCookiePolicy: setCookiePolicy,
            getCookiePolicy: getCookiePolicy,
            getDefaultLanguage: getDefaultLanguage,
            getLanguageOptions: getLanguageOptions,
            logIn: logIn,
            getPrivacyPDFPath: getPrivacyPDFPath,
            getUserGuidePdfUrl: getUserGuidePdfUrl,
            getClient: getClient,
            getThemesInfo: getThemesInfo,
            getTotpDetail: getTotpDetail,
            validateTotp: validateTotp,
            renewCsrfToken: renewCsrfToken,
            getContactUsURL:getContactUsURL
        };

        function setDefaultName(userName) {
            if (userName) {
                $cookies.put('REMEMBER_ME_USERID', userName);
                return true;
            } else {
                return false;
            }
        }
        function getDefaultName() {
            return $cookies.get('REMEMBER_ME_USERID') || null;
        }
        function setTOTPMessage(message) {
            if (message) {
                $cookies.put('TOTP_MESSAGE', message);
            }
        }
        function getTOTPMessage() {
            return $cookies.get('TOTP_MESSAGE') || null;
        }
        function getDefaultRememberMe() {
            return $cookies.get('REMEMBER_ME_USERID') ? true : false;
        }
        function setCookiePolicy(clientData) {
            if (clientData && _.isNumber(clientData.ssoCookieAcceptPeriod) && !$cookies.getObject('COOKIE_POLICY')) {
                $cookies.put('COOKIE_POLICY', 'true', {expires: moment().add(clientData.ssoCookieAcceptPeriod < 0 ? COOKIE_ACCEPT_MAX_DAYS : clientData.ssoCookieAcceptPeriod, 'days').format('ddd, DD MMM YYYY HH:mm:ss')});
            }
        }
        function getCookiePolicy() {
            return $cookies.getObject('COOKIE_POLICY') ? true : false;
        }
        function getDefaultLanguage(options) {
            return _getOption($cookies.get('LOCALE')) || _getOption(I18nUtil.getBrowserLocale()) || _getOption('en_US') || null;
            function _getOption(language) {
                return _.find(options, {id: language}) ? language : undefined;
            }
        }
        function getLanguageOptions() {
            var deferred = $q.defer();
            I18nUtil.getAllLanguages()
                .then(function(languages) {
                    deferred.resolve(_.map(languages, function(language) {
                        return {
                            text: language.description,
                            id: language.locale
                        };
                    }));
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function  getHeader(timeout){
            var header = {'Content-Type': 'application/x-www-form-urlencoded'};
            if(timeout && timeout === 'Y'){
                header['X-timeout'] = timeout;
            }
            return header;
        }
        function logIn(username, password, rememberMe, preferredLanguage,timeout) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: applicationContextRoot + '/j_spring_security_check',
                data: $.param({
                    j_username: username,
                    j_password: password,
                    preferredClientId: UserPreferences.getMostRecentClient(username) || null,
                    preferredLanguage: preferredLanguage,
                    _spring_security_remember_me: rememberMe
                }),
                headers: getHeader(timeout)
            })
                .then(function(loginResponse) {
                    if (loginResponse.data.errorMessage) {
                        deferred.reject(loginResponse.data.errorMessage);
                    } else {
                        AlphaApiUtils.clearHttpCache();
                        UserDetailsInterface.setLanguageToSession(
                            preferredLanguage,
                            function success() {
                                deferred.resolve(loginResponse.data);
                            },
                            function error(setLanguageResponse) {
                                deferred.reject(setLanguageResponse.data.errorMessage);
                            }
                        );
                    }
                })
                .catch(function(loginResponse) {
                    var errorMessage;
                    if (_.isString(loginResponse.data)) {
                        /* TODO: Due to the login request serving errors as HTML that varies
                            based on the Tomcat version, we must parse the messages we expect
                            to see. This will not be compatible with internationalization. At
                            some point we will need to serve this data in a consistent way so
                            any message from the server can be printed. */
                        var clientNameString;
                        if (_.get(loginResponse.data.match(/User cannot access client [^\s<]*/), '[0]')) {
                            clientNameString = _.get(loginResponse.data.match(/User cannot access client [^\s<]*/), '[0]').replace('User cannot access client ', '');
                            errorMessage = I18nUtil.getI18nString('ERR_USER_CANNOT_ACCESS_CLIENT', 'User cannot access client {{clientName}}.', {clientName: clientNameString});
                        } else if (_.get(loginResponse.data.match(/404 Client [\S]* not found\./), '[0]')) {
                            clientNameString = _.get(loginResponse.data.match(/404 Client [\S]* not found\./), '[0]').replace('404 Client ', '').replace(' not found.', '');
                            errorMessage = I18nUtil.getI18nString('ERR_CLIENT_NOT_FOUND', 'Client {{clientName}} not found.', {clientName: clientNameString});
                        } else if (loginResponse.data.match(/Cannot login as your user is not connected to a valid client,/)) {
                            errorMessage = I18nUtil.getI18nString('USER_CLIENT_INVALID', 'Cannot login as your user is not connected to a valid client, please contact support');
                        } else {
                            errorMessage = I18nUtil.getI18nString('UNKKNOWN_LOGIN_ERR', 'Unknown error logging in.');
                        }
                    } else {
                        errorMessage = loginResponse.status + ' ' + loginResponse.statusText;
                    }
                    deferred.reject(errorMessage);
                });
            return deferred.promise;
        }

        function getTotpDetail() {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function() {
                    UserDetailsInterface.getTotpDetails(
                        function success(data) {
                            deferred.resolve(data);
                        },
                        function error(reason) {
                            deferred.reject(reason);
                        }
                    );
                });
            return deferred.promise;
        }

        function validateTotp(totpCode, trustCookies, language) {
            var deferred = $q.defer();
            UserDetailsInterface.setLanguageToSession(
                language,
                function success() {
                    UserPreferences.getClientId()
                        .then(function(clientId){
                            UserDetailsInterface.validateTotp(
                                totpCode,
                                trustCookies || false,
                                clientId,
                                function success(totpResponse) {
                                    deferred.resolve(totpResponse.redirectionUrl);
                                },
                                function error(errorMessage) {
                                    deferred.reject(errorMessage.data.errorMessage);
                                }
                            );
                        });
                },
                function error(errorMessage) {
                    deferred.reject(errorMessage.data.errorMessage);
                }
            );
            return deferred.promise;
        }

        /**
         * Passively renews Angular's CSRF token by calling a simple endpoint.
         * This can be accomplished with any endpoint that sets the token.
         *
         * @method renewCsrfToken
         *
         * @returns {Object} A promise to be resolved or rejected with an error
         */
        function renewCsrfToken() {
            var deferred = $q.defer();
            UserPreferencesInterface.getUserPreferences(
                function success() {
                    deferred.resolve();
                }, function error(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getPrivacyPDFPath(language) {
            var deferred = $q.defer();
            DataModelDesignInterface.getPrivacyPdfUrl(
                language,
                function success(data) {
                    deferred.resolve(data);
                }, function error(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getUserGuidePdfUrl() {
            var deferred = $q.defer();
            DataModelDesignInterface.getUserGuidePdfUrl(
                I18nUtil.getLanguage(),
                function success(data) {
                    deferred.resolve(data);
                }, function error(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getThemesInfo() {
            var deferred = $q.defer();
            DataModelDesignInterface.getThemesInfo(
                function success(data) {
                    deferred.resolve(data);
                }, function error(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getClient() {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId){
                    DataModelDesignInterface.getClientFromCache(
                        clientId,
                        function success(response) {
                            deferred.resolve(response.client);
                        }, function error(reason) {
                            deferred.reject(reason);
                        });
                });
            return deferred.promise;
        }

        function getContactUsURL() {
            var deferred = $q.defer();
            DataModelDesignInterface.getContactUsUrl(
                I18nUtil.getLanguage(),
                function success(data) {
                    deferred.resolve(data);
                }, function error(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    }
})();
