(function () {
    'use strict';

    angular
        .module('alpha.common.directives.alphaMegaMenu', [
            'ui.bootstrap',
            'alpha.common.services.alphaRecentRecords',
            'alpha.utils.recordData',
            'alpha.utils.I18n'
        ])
        .directive('alphaMegaMenu', alphaMegaMenu);

    alphaMegaMenu.$inject = ['AlphaRecentRecordsService', 'RecordDataUtil','I18nUtil'];

    function alphaMegaMenu(AlphaRecentRecordsService, RecordDataUtil,I18nUtil){
        return {
            restrict: 'E',
            replace: true,
            link: link,
            templateUrl : applicationContextRoot + '/static/custom/common/app/partials/directives/alphaMegaMenu.directive.html',
            scope: {
                show: '=',
                type: '='
            }
        };

        function link(scope){
            var limit = 10,
                recordTypeTabCount = 0;
            scope.records = [];
            scope.columns = AlphaRecentRecordsService.getLimitedRecordsGridColumnConfig();
            scope.toggleShow = toggleShow;
            scope.openRecordsTable = openRecordsTable;
            scope.openRecordAsCompiledView = openRecordAsCompiledView;
            scope.openDefaultLayout = openDefaultLayout;
            scope.setOverFlow = setOverFlow;
            scope.isRecentRecords = isRecentRecords;
            scope.getRecentPopUpData = getRecentPopUpData;
            init();
            scope.$watch('show', function(newState){
                if(newState && isRecentRecords()){
                    _getRecentRecords();
                }
            });
            function init(){
                AlphaRecentRecordsService.getRecordTypes()
                    .then(function (recordTypes) {
                        var allRecords = _.chain(recordTypes.mapList)
                            .sort(_sortByDescription)
                            .map(_getRecordTypeObject)
                            .value();
                        scope.recordTypes =  _.filter(allRecords, function(record) {
                            return Boolean(record.showInAddRecordMenu) === true; });
                        if (isRecentRecords()) {
                            _getRecentRecords();
                        }
                    });

                function _sortByDescription(x,y){
                    return I18nUtil.sortByLocale(_.toLower(RecordDataUtil.getDisplayValue(x.description, 'Description')),_.toLower(RecordDataUtil.getDisplayValue(y.description, 'Description')),true);
                }
                function _getRecordTypeObject(object){
                    return {
                        description: object.description,
                        id: object.recordType,
                        showInAddRecordMenu: object.showInAddRecordMenu
                    };
                }
            }
            function openRecordsTable(){
                AlphaRecentRecordsService.openRecordsTable();
                toggleShow();
            }
            function openRecordAsCompiledView(record){
                AlphaRecentRecordsService.openRecordAsCompiledView(record);
                toggleShow();
            }
            function openDefaultLayout(recordType){
                AlphaRecentRecordsService.openDefaultView(recordType.id, recordTypeTabCount++);
                toggleShow();
            }
            function toggleShow(){
                scope.recordTypeFilter = '';
                scope.show = !scope.show;
            }
            function setOverFlow(event, record){
                record.isOverFlow = event.target.offsetWidth < event.target.scrollWidth;
            }
            function _filterNullRecords(object){
                return _.isNumber(object.id);
            }
            function  _getRecentRecords(){
                AlphaRecentRecordsService.getRecentRecords(limit).then(function (records){
                    scope.records = _.chain(AlphaRecentRecordsService.formatRecords(records))
                        .filter(_filterNullRecords)
                        .orderBy('openedTimestamp','desc')
                        .value();
                });
            }
            function isRecentRecords(){
                return scope.type === 'recentRecords';
            }

            /**
             * Get ToolTip data of recent record
             * @param recentRecord
             */
            function getRecentPopUpData(recentRecord) {
                if (null != recentRecord && null == recentRecord.toolTipData) {
                    AlphaRecentRecordsService.getRecentPopUpData(recentRecord);
                }
            }
        }
    }
})();
