(function() {
    'use strict';
    angular
        .module('alpha.login.Login')
        .constant('USER_ID', 'userId')
        .controller('LoginController', LoginController);

    LoginController.$inject = [
        '$cookies',
        '$scope',
        '$window',
        'LoaderService',
        'LoginService',
        'AlphaLocalStorageService',
        'I18nUtil',
        'LOGIN_PAGE_PATH',
        'Utils',
        '$sce',
        'UserPreferences',
        'USER_ID'
    ];

    function LoginController(
        $cookies,
        $scope,
        $window,
        LoaderService,
        LoginService,
        AlphaLocalStorageService,
        I18nUtil,
        LOGIN_PAGE_PATH,
        Utils,
        $sce,
        UserPreferences,
        USER_ID
    ) {
        var vm = this;

        vm.doLogin = doLogin;
        vm.validateTotp = validateTotp;
        vm.togglePrivacyPolicy = togglePrivacyPolicy;
        vm.openPrivacyStatement = openPrivacyStatement;
        vm.openUserGuidePdfUrl = openUserGuidePdfUrl;
        vm.forgotPassword = forgotPassword;
        vm.checkUserForIdP = checkUserForIdP;
        vm.navigateToLoginPage=navigateToLoginPage;
        vm.openContactUs = openContactUs;

        // Initialization

        vm.copyrightDate = Date.now();
        vm.restoreTabs = false;
        vm.login = {
            name: LoginService.getDefaultName(),
            password: null,
            rememberMe: LoginService.getDefaultRememberMe(),
            cookiePolicy: LoginService.getCookiePolicy(),
            messages: []
        };
        vm.isIE = isIE();
        vm.TOTPMaxAgeMessage = LoginService.getTOTPMessage();
        _bindWatchers();
        _focusInput();
        _loadLanguageOptions();
        _loadThemesInfo();

        // Public methods

        function doLogin() {
            if (vm.login.cookiePolicy) {
                LoaderService.startLoading();
                LoginService.logIn(vm.login.name, vm.login.password, vm.login.rememberMe, vm.login.preferredLanguage)
                    .then(function(loginData) {
                        if (!loginData) {
                            return;
                        }
                        AlphaLocalStorageService.setDataInStorage(_.toUpper(vm.login.name), {restoreTabs: vm.restoreTabs});
                        if (loginData.IS_MFA) {
                            UserPreferences.getCurrentPreferences()
                                .then(function(prefs) {
                                    vm.TOTPMaxAgeMessage = I18nUtil.getI18nString('LBL_TRUST_COOKIES_MESSAGE', 'Trust this device for {{TOTPMaxAge}} days', {TOTPMaxAge: prefs.mfaTOTPCookiesMaxAge || 30});
                                    LoginService.setTOTPMessage(vm.TOTPMaxAgeMessage);
                                    if (LoginService.setDefaultName(vm.login.name)) {
                                        $window.location = loginData.redirectionUrl;
                                    }
                                });
                        } else {
                            LoginService.getClient()
                                .then(function (clientData) {
                                    LoginService.setCookiePolicy(clientData);
                                    $window.location = loginData.redirectionUrl;
                                });
                        }
                        AlphaLocalStorageService.setDataInStorage(USER_ID, vm.login.name);
                    })
                    .catch(_showError)
                    .finally(LoaderService.stopLoading);
            } else {
                _showError(I18nUtil.getI18nString('ERR_COOKIE_POLICY', 'Agreement to Cookie Policy is required to continue.'));
            }
        }

        function validateTotp() {
            LoaderService.startLoading();
            LoginService.validateTotp(vm.login.totpCode, vm.login.trustCookies, vm.login.preferredLanguage)
                .then(function(redirectionUrl) {
                    if (_.endsWith(redirectionUrl, LOGIN_PAGE_PATH)) {
                        vm.navigateToLoginPage();
                    } else {
                        LoginService.getClient()
                            .then(function (clientData) {
                                LoginService.setCookiePolicy(clientData);
                                $window.location = redirectionUrl;
                            });
                    }
                })
                .catch(_showError)
                .finally(LoaderService.stopLoading);
        }

        function navigateToLoginPage() {
            $window.location = applicationContextRoot + LOGIN_PAGE_PATH;
        }

        function togglePrivacyPolicy() {
            vm.privacyPolicyIsOpen = !vm.privacyPolicyIsOpen;
        }

        function openPrivacyStatement(){
            LoginService.getPrivacyPDFPath(I18nUtil.getLanguage()).then(function(pdfURL){
                if(_.get(pdfURL,'privacyDoc.url')) {
                    var counter = new Date().getTime();
                    $window.open(pdfURL['privacyDoc.url'], 'privacystatement'+counter);
                } else {
                    $window.alert(I18nUtil.getI18nString('ERR_PRIVACY_STATEMENT', 'There is an error please contact your administrator for privacy statement'));
                }
            }).catch(function(){
                $window.alert(I18nUtil.getI18nString('ERR_PRIVACY_STATEMENT', 'There is an error please contact your administrator for privacy statement'));
            });
        }

        function openUserGuidePdfUrl(){
            LoginService.getUserGuidePdfUrl()
                .then(function(userGuide){
                    if (_.get(userGuide,'userGuide.url')) {
                        var counter = new Date().getTime();
                        $window.open(userGuide['userGuide.url'], 'userguide' + counter);
                    } else {
                        $window.alert(I18nUtil.getI18nString('MSG_ALERT', 'Alert'), I18nUtil.getI18nString('ERR_USER_GUIDE', 'There is an error please contact your administrator for user guide'));
                    }
                })
                .catch(function(){
                    $window.alert(I18nUtil.getI18nString('ERR_USER_GUIDE', 'There is an error please contact your administrator for user guide'));
                });
        }

        function openContactUs() {
            LoginService.getContactUsURL().then(function (pdfURL) {
                if (_.get(pdfURL, 'contactUs.url')) {
                    var counter = new Date().getTime();
                    $window.open(pdfURL['contactUs.url'], 'contactUs'+counter);
                } else {
                    $window.alert(I18nUtil.getI18nString('MSG_ALERT', 'Alert'), I18nUtil.getI18nString('ERR_CONTACT_US', 'There is an error please contact your administrator to contact us'));
                }
            }).catch(function () {
                $window.alert(I18nUtil.getI18nString('MSG_ALERT', 'Alert'), I18nUtil.getI18nString('ERR_CONTACT_US', 'There is an error please contact your administrator to contact us'));
            });
        }

        function forgotPassword() {
            if (vm.login.cookiePolicy) {
                $window.location = applicationContextRoot + '/dynamic/password-management.jsp';
            } else {
                _showError(I18nUtil.getI18nString('ERR_COOKIE_POLICY', 'Agreement to Cookie Policy is required to continue.'));
            }
        }

        function checkUserForIdP() {
            if (vm.login.name) {
                Utils.redirectForUserIdP(vm.login.name);
            }
        }

        // Private functions

        function _showError(message) {
            vm.login.messages = [{
                text: message,
                messageType: 'Error'
            }];
        }

        function _bindWatchers() {
            $scope.$watch(function() {
                return vm.login.name;
            }, function(name){
                var storageValue = AlphaLocalStorageService.getDataFromStorage(_.toUpper(name));
                if(_.isObject(storageValue) && _.isBoolean(storageValue.restoreTabs)){
                    vm.restoreTabs = storageValue.restoreTabs;
                }
            });
        }

        function _focusInput() {
            if (vm.login.name) {
                angular.element('#j_password').focus();
            } else {
                angular.element('#j_username').focus();
            }
        }

        function _loadLanguageOptions() {
            LoaderService.startLoading();
            LoginService.getLanguageOptions()
                .then(function(options) {
                    vm.languageOptions = options;
                    vm.login.preferredLanguage = LoginService.getDefaultLanguage(options);
                })
                .catch(_showError)
                .finally(LoaderService.stopLoading);
        }

        function _loadThemesInfo() {
            LoginService.getThemesInfo()
                .then(function(data) {
                    vm.themesInfo = data;
                    vm.hotSpotUrl = $sce.trustAsResourceUrl(data['hubSpot.url']);
                });
        }

        function isIE() {
            return ($window.navigator.userAgent.indexOf('Trident') > 0 || $window.navigator.userAgent.indexOf('MSIE') > 0);
        }
    }
})();
