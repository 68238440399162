'use strict';

angular.module('alpha.utils', [
    'alpha.utils.Utils',
    'alpha.utils.Events',
    'alpha.utils.Loader',
    'alpha.utils.Modals',
    'alpha.utils.AuthenticationUtils',
    'alpha.utils.RequiredFieldUtils',
    'alpha.utils.I18n',
    'alpha.utils.recordData'
]);

angular.module('alpha.utils.Utils', ['ngSanitize', 'AlphaApi', 'UserPreferences'])
    .factory('Utils', ['$window','$http','$sanitize', 'DataProviderInterface', '$q', 'DataModelDesignInterface', 'UserPreferences',  function($window,$http,$sanitize, DataProviderInterface, $q, DataModelDesignInterface, UserPreferences) {
        return {
            /**
             * Recursively removes all instances of a specific property from an object.  Skips over internal (starting with $) props.
             * @param {Object} [obj [The object from which to remove the properties.]
             * @param {String|Array} [props] [The property (or array of properties) to remove.  Can be a string or array of strings.]
             * @param {Array} [excluded] [Objects with this key will not be modified (and neither will their descendants).]
             */
            removePropsFromObj: function(obj, props, excluded) {
                var shouldRemove;  // function that determines which properties get removed

                if (!props) {
                    return;
                } else if (props instanceof Array) {
                    shouldRemove = function(key, _props) {
                        return _.includes(_props, key);
                    }
                } else {
                    shouldRemove = function(key, _props) {
                        return key === _props;
                    }
                }

                // If 'excluded' is supplied and is just a string, put it in array for our purposes
                if(excluded && !(excluded instanceof Array)) {
                    excluded = [].concat(excluded);
                }

                impl(obj, props);

                // internal impl function
                function impl(_obj, _props) {
                    var isArray = _obj instanceof Array,
                        keyIsExcluded;

                    angular.forEach(_obj, function (value, key) {
                        if(excluded) {
                            keyIsExcluded = _.find(excluded, function (e) {
                                return e === key;
                            });
                        }

                        if (shouldRemove(key, _props)) {
                            isArray ? _obj.splice(key, 1) : delete _obj[key];
                        } else if (typeof value === 'object') {
                            // don't recurse through internal properties starting with $
                            // don't recurse through objects that are explicitly excluded
                            if (!(!isArray && key.indexOf('$') === 0) && !keyIsExcluded) {
                                impl(value, _props);
                            }
                        }
                    });
                }
            },
            /**
             * Returns a new object or array with native Angular properties removed.
             * Will only remove properties from the top level.
             *
             * @param {Object|Array} obj Object or array to be cleaned up
             *
             * @returns {Object|Array} Equivalent object or array with the properties removed
             */
            removeAngularProps: function(obj) {
                if (_.isArray(obj)) {
                    return _.filter(obj, function(val, key) {
                        return !_.startsWith(key, '$');
                    });
                } else if (_.isObject(obj)) {
                    return _.omitBy(obj, function(val, key) {
                        return !obj.hasOwnProperty(key) || _.startsWith(key, '$');
                    });
                }
            },
            /**
             * Converts any string to an HTML ID by replacing invalid characters with underscores
             *
             * @param {String} source String to be converted
             *
             * @returns {String} Equivalent string to use for an HTML ID
             */
            getHtmlId: function(source) {
                return _.isString(source) ? source.replace(/([^a-z0-9-]+)/gi, '_') : '';
            },
            /**
             * Converts any string to a service name by deburring it and replacing invalid characters with underscores
             *
             * @param {String} source String to be converted
             *
             * @returns {String} Equivalent string to use for a service name
             */
            getServiceNameFromString: function(source) {
                return _.isString(source) ? _.deburr(source).replace(/[^a-z0-9]/gi, '_') : '';
            },
            /**
             * Formats the arrays for lookup filtering criterion and values
             *
             * @param {String} enableLookupSecurity String "true" or "false"
             * @param {String} enableExclusiveObjects String "true" or "false"
             * @param {String} fieldTypeId String of fieldType EG. RecordTypeId.FieldTypeId
             *
             * @returns {Object} Object containing the variables and values for the lookup request
             */
            getLookupFilterVariablesAndValues: function(enableLookupSecurity, enableExclusiveObjects, fieldTypeId) {
                var variables = [], values = [];
                if (enableLookupSecurity === 'true' || enableExclusiveObjects === 'true') {
                    variables.push('fieldType');
                    values.push(fieldTypeId);
                }

                if (enableExclusiveObjects === 'true') {
                    variables.push('exclusiveObjects');
                    values.push(true);
                }
                return {
                    variables: variables,
                    values: values,
                };
            },
            /**
             * Converts a description to a service name
             *
             * @param {Object} description Description containing the string to be converted
             * @param {String} [language] Language to get the string from; defaults to US English
             *
             * @returns {String} Equivalent string to use for a service name
             */
            getServiceNameFromDescription: function(description, language) {
                var source;
                language = language || 'en_US';
                if (_.isObject(description)) {
                    source = _.has(description, language) ? description[language] : description['en_US'];
                }
                return _.isObject(description) ? this.getServiceNameFromString(source) : '';
            },
            /**
             * Calculates the width of a rendered string of text
             *
             * @param {String} text The text to measure
             * @param {String} [fontWeight] Font weight; defaults to body style
             * @param {String} [fontSize] Font size including unit; defaults to body style
             * @param {String} [fontFamily] Font family; defaults to body style
             *
             * @returns {Number} Width of rendered text in pixels
             */
            getRenderedTextWidth: function(text, fontWeight, fontSize, fontFamily) {
                var canvas = document.createElement('canvas'),
                    context = canvas.getContext('2d'),
                    defaultStyle = getComputedStyle(document.body),
                    metrics;
                fontWeight = fontWeight|| defaultStyle.fontWeight;
                fontSize = fontSize || defaultStyle.fontSize;
                fontFamily = fontFamily || defaultStyle.fontFamily;
                context.font = fontWeight + ' ' + fontSize + ' ' + fontFamily;
                metrics = context.measureText(text);
                canvas.remove();
                return Math.ceil(metrics.width);
            },
            /**
             * Downloads a file
             *
             * @param {String} fileName String Name of the file
             * @param {Object} fileContent Contents of the file to be converted to a Blob
             * @param {String} mimeType Attachment type of the file
             */
            downloadFile: function(fileName, fileContent, mimeType) {
                var blobUrl, anchor;
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(new Blob([fileContent], {type: mimeType}), fileName); // For IE11
                } else {
                    blobUrl = window.URL.createObjectURL(new Blob([fileContent], {type: mimeType}));
                    anchor = jQuery('<a>').css('display', 'none').attr('href', blobUrl).attr('download', fileName);
                    jQuery('body').append(anchor);
                    anchor[0].click();
                    window.URL.revokeObjectURL(blobUrl);
                    anchor.remove();
                }
            },
            /**
             *
             * @param url
             */
            downloadUrl: function(url) {
                var anchor = jQuery('<a>').css('display', 'none').attr('href', url);
                jQuery('body').append(anchor);
                anchor[0].click();
            },
            /**
             * Returns a name for a file based on the response headers
             *
             * @param {Object} response A response from an HTTP request
             *
             * @returns {String} The name of the file
             */
            getFilenameFromResponse: function(response) {
                return _.split(response.headers('content-disposition'), '=')[1] || 'Document';
            },
            redirectForUserIdP:function(userId) {
                $http({
                    method: 'GET',
                    url: applicationContextRoot + '/security/metadata/user/' + userId
                }).then(function(response) {
                    // Check for our 'dummy' idp URL (from com.aon.esolutions.alpha.security.web.controller.SAMLMetadataController.getUsersIdentityProviderRedirect)
                    if (response.data.idpEntityId && response.data.idpEntityId !== 'http://www.okta.com/exkpex4g1nIRQHWE70h7') {
                        $window.location = applicationContextRoot + "/saml/login?idp=" + encodeURIComponent(response.data.idpEntityId) + "&subject=" + encodeURIComponent(userId);
                    }
                });
            },
            /**
             * Converts a string to its html characters completely.
             *
             * @param {String} str String with unescaped HTML characters
             **/
            htmlEntitiesEncode : function(str){
                var buf = [];
                for (var i=str.length-1;i>=0;i--) {
                    buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
                }
                return buf.join('');
            },
            /**
             * Converts an html characterSet into its original character.
             *
             * @param {String} str htmlSet entities
             **/
            htmlEntitiesDecode : function(str) {
                return str.replace(/&#(\d+);/g, function(match, dec) {
                    return String.fromCharCode(dec);
                });
            },
            /**
             * sanitize removes XSS script tags.
             *
             * @param {String} str
             **/
            sanitize : function(str) {
                return this.htmlEntitiesDecode($sanitize(str));
            },
            /**
             * Convert string "['a','b','c']" to array ['a','b','c']
             * Returns array if conversion successful
             * @param {String} str
             */
            convertStringToArray: function (str) {
                if (_.isEmpty(str)) {
                    return [];
                }
                if (_.isString(str)) {
                    var newArray;
                    try{
                        newArray = JSON.parse(str.replace(/'/g, '"'));
                    } catch (e) {
                        var strWithoutSquareBrackets = str.replace(/[\[\]]/g, '');
                        var strArray = strWithoutSquareBrackets.split(',');
                        newArray = _.map(strArray, _.trim);
                    }
                    return _.isArray(newArray) ? newArray : [];
                } else if (_.isArray(str)) {
                    return str;
                }
                return [];
            },
            stripHtml: function (string) {
                string = string.replace(/<[^>]+>/gm, '') // tags
                    .replace(/&nbsp;/gm, ' ')
                    .replace(/&amp;/gm, '&')
                    .replace(/&gt;/gm, '>')
                    .replace(/&lt;/gm, '<')
                    .replace(/&quot;/gm, '"');
                return string;
            },
            getFileTypeWhiteList: function() {
                var deferred = $q.defer();
                var whitelistData;
                this.fetchClientData()
                    .then(function success(clientData){
                        whitelistData = clientData.fileTypeWhitelist;
                        if(whitelistData == null || whitelistData.length == 0){
                DataModelDesignInterface.getSystemValueFromCache('ALPHA', 'FILE_TYPE_WHITELIST', _success, _error);
                        } else {
                            _success({value: whitelistData});
                        }
                    }, function error(reason){
                        deferred.reject(reason);
                    });
                function _success(data) {
                    deferred.resolve(data);
                }
                function _error(reason) {
                    deferred.reject(reason);
                }

                return deferred.promise;
            },
            getAvatarFileTypeWhiteList: function() {
                var deferred = $q.defer();
                var whitelistData;
                this.fetchClientData()
                    .then(function success(clientData){
                        whitelistData = clientData.avatarFileTypeWhitelist;
                        if(whitelistData == null || whitelistData.length == 0){
                DataModelDesignInterface.getSystemValue('ALPHA', 'AVATAR_FILE_TYPE_WHITELIST', _success, _error);
                        } else {
                            _success({value: whitelistData});
                        }
                    }, function error(reason){
                        deferred.reject(reason);
                    });
                function _success(data) {
                    deferred.resolve(data);
                }
                function _error(reason) {
                    deferred.reject(reason);
                }

                return deferred.promise;
            }, isIEBrowser: function () {
                var ua = navigator.userAgent;
                /* MSIE used to detect old browsers and Trident used to newer ones*/
                return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
            }, isSafariBrowser: function () {
                var ua = navigator.userAgent;
                return ua.indexOf("safari") > -1;
            }, isValidHexCode: function(colorCode) {
                var isOk = false;
                if (colorCode != undefined && colorCode.trim() !== null &&
                    colorCode.trim() !== '') {
                isOk = /^#([0-9A-F]{3}){1,2}$/i.test(colorCode.trim());
            }
            return isOk;
        }, fetchClientData: function () {
                var deferred = $q.defer();
                UserPreferences.getClientId()
                    .then(function success(clientId) {
                        DataModelDesignInterface.getClientFromCache(
                            clientId,
                            function success(data) {
                                deferred.resolve(data.client);
                            },
                            function error(reason) {
                                deferred.reject(reason);
                            }
                        );
                    })
                    .catch(function (reason) {
                        deferred.reject(reason);
                    });
                return deferred.promise;

        }
        };
    }])
    .factory('UUIDGenerator', function() {
        return {
            // This generates a pseudo-random UUID lookalike but does not meet the RFC4122 sec4.4 requirements
            generate: function() {
                function S4() {
                    return (
                        Math.floor(
                            Math.random() * 0x10000 /* 65536 */
                        ).toString(16)
                    );
                };

                return (
                S4() + S4() + "-" +
                S4() + "-" +
                S4() + "-" +
                S4() + "-" +
                S4() + S4() + S4()
                );
            }
        };
    })
    .filter('startFrom', function() {
        return function(input, start) {
            start = +start; //parse to int
            if (input)
                return input.slice(start);
            else
                return null;
        };
    });

angular.module('alpha.utils.Events', ['alpha.utils.Utils']).factory('Events', ['UUIDGenerator', function(UUIDGenerator) {
    var GLOBAL_CHANNEL = 'global';
    var PRINT_SUBS_TOPIC = 'debug-print-subscriptions';

    /*
     tracks the subscriber tree:
     {
     "channelName1": {
     "topic1": {
     "subscriber1": {
     "callback": callbackFn,
     "scope": scopeObj
     }
     }
     }
     }
     */
    var subscribers = {};
    var subscriberNames = {};

    /*
     tracks subscribers by scope for cleanup uses
     {
     "scopeId1": [
     {
     "channel": channelName1,
     "topics": topicNameOrArrayOfNames1,
     "name": subscriberName1
     },
     {
     "channel": channelName2,
     "topics": topicNameOrArrayOfNames2,
     "name": subscriberName2
     }
     ]
     }
     */
    var scopeSubscribers = {};

    var parentWindow = null;
    var childWindows = {};

    // create a reachable hook for other windows to subscribe to us
    window.AlphaEventService = {
        debug: false,
        windowUUID: UUIDGenerator.generate(),  // uniquely id this window
        publish: function(channel, topic, data, incomingEventWindowUUID) {
            if (channel === GLOBAL_CHANNEL) {
                publishGlobalImpl(topic, data, incomingEventWindowUUID);
            } else {
                publishImpl(channel, topic, data, incomingEventWindowUUID);
            }
        },
        publishGlobal: function(topic, data, incomingEventWindowUUID) {
            publishGlobalImpl(topic, data, incomingEventWindowUUID);
        },
        subscribeChildWindow: function(child) {
            childWindows[child.AlphaEventService.windowUUID] = child;
        },
        unsubscribeChildWindow: function(child) {
            unsubscribeChildWindowImpl(child);
        }
    };
    var svc = window.AlphaEventService;  // for convenience

    try {
        if (window.opener != null || window.parent != window) {
            // register with parent window to receive global events
            var parentTarget = (window.parent == window) ? window.opener : window.parent;

            if (parentTarget.AlphaEventService) {
                parentTarget.AlphaEventService.subscribeChildWindow(window);
                parentWindow = parentTarget;

                // unsubscribe from the parent when our window closes
                window.onbeforeunload = function () {
                    try {
                        parentWindow.AlphaEventService.unsubscribeChildWindow(window);
                    } catch (e) { /* don't care */ }
                }
            }
        }
    } catch (e) { /* don't care */ }

    if (svc.debug) {
        console.log("EVT_SVC: AlphaEventService init on window " + svc.windowUUID + " (" + window.location.href + ")");

        try {
            if (parentWindow) {
                console.log("EVT_SVC: window " + svc.windowUUID + " registered as child of " + parentWindow.AlphaEventService.windowUUID + " (" + parentWindow.location.href + ")");
            }
        } catch (e) { /* don't care */ }
    }


    var subscribeGlobalFn = function(name, scope, topics, callback) {
        subscribeFn(name, scope, GLOBAL_CHANNEL, topics, callback);
    };

    var subscribeFn = function(name, scope, channel, topics, callback) {
        if (_.isEmpty(name)) {
            name = _getRandomId();
        }
        if (subscriberNames[name]) {
            if (svc.debug) { console.error("EVT_SVC: subscriber '" + name + "' already exists - aborting subscribe"); }
            return;
        } else {
            subscriberNames[name] = true;
        }
        if (svc.debug) { console.log("EVT_SVC: subscribing " + name + " to " + channel + "::" + topics + " on " + svc.windowUUID + "::" + scope.$id); }

        var channelSubs = subscribers[channel];
        if (!channelSubs) {
            channelSubs = subscribers[channel] = {};
        }

        if (angular.isArray(topics)) {
            angular.forEach(topics, function(topic) {
                subscribeToTopic(name, scope, channelSubs, topic, callback);
            });
        } else {
            subscribeToTopic(name, scope, channelSubs, topics, callback);
        }

        trackScopeSubscriber(name, scope, channel, topics);
    };

    var unsubscribeFn = function(name, scope, channel, topic) {
        // remove from subscribers
        delete subscriberNames[name];
        var chanSubs = subscribers[channel];
        if (chanSubs) {
            deleteSubFromTopic(chanSubs, topic, name);
        }

        // remove from scopeSubscribers
        var scopeSubs = scopeSubscribers[scope.$id];
        angular.forEach(scopeSubs, function(scopeSub, subId) {
            if (scopeSub.name === name) {
                if (_.isArray(scopeSub.topics) && _.includes(scopeSub.topics, topic)) {
                    scopeSub.topics.splice($.inArray(topic, scopeSub.topics), 1);
                    if (scopeSub.topics && scopeSub.topics.length === 0) {
                        scopeSubs.splice(subId, 1);
                    }
                }else if(scopeSub.topics === topic){
                    scopeSubs.splice(subId, 1);
                }
            }
        });
    };

    var unsubscribeGlobalFn = function(name, scope, topic) {
        unsubscribeFn(name, scope, GLOBAL_CHANNEL, topic);
    };

    var publishFn = function(channel, topic, data) {
        publishImpl(channel, topic, data, null);
    };

    var publishGlobalFn = function(topic, data) {
        publishGlobalImpl(topic, data, null);
    };

    var unsubscribeChildWindowFn = function(child) {
        unsubscribeChildWindowImpl(child);
    };

    function publishImpl(channel, topic, data, incomingEventWindowUUID) {
        if (svc.debug) {
            if (!incomingEventWindowUUID) {
                console.log("EVT_SVC: publishing event " + channel + "::" + topic + " from source window " + svc.windowUUID);
            } else {
                console.log("EVT_SVC: processing event " + channel + "::" + topic + " in window " + svc.windowUUID + " received from other window " + incomingEventWindowUUID);
            }

            if (topic === PRINT_SUBS_TOPIC) { printSubs(); }
        }

        var chanSubs = subscribers[channel];
        if (chanSubs) {
            if (topic !== 'all') {
                angular.forEach(chanSubs[topic], function (sub, name) {
                    if (svc.debug) { console.log("EVT_SVC: firing " + channel + "::" + topic + " for subscriber " + name + " on " + svc.windowUUID + "::" + sub.scope.$id); }
                    asyncPublish(sub, topic, data);
                });
            }

            angular.forEach(chanSubs['all'], function (sub, name) {
                if (svc.debug) { console.log("EVT_SVC: firing " + channel + "::" + topic + " for subscriber " + name + " on " + svc.windowUUID + "::" + sub.scope.$id); }
                asyncPublish(sub, topic, data);
            });
        }
    }

    // handles global events propagating to parent and child windows
    function publishGlobalImpl(topic, data, incomingEventWindowUUID) {
        // first publish to subscribers on the current window
        publishImpl(GLOBAL_CHANNEL, topic, data, incomingEventWindowUUID);

        angular.forEach(childWindows, function(child, childWindowUUID) {
            try {
                if (incomingEventWindowUUID !== childWindowUUID) {
                    if (svc.debug) {
                        console.log("EVT_SVC: sending global::" + topic + " from window " + svc.windowUUID + " to child " + childWindowUUID);
                    }
                    child.AlphaEventService.publishGlobal(topic, data, svc.windowUUID);
                }
            } catch (e) {
                if (svc.debug) {
                    console.log("EVT_SVC: child " + childWindowUUID + " is inaccessible from window " + svc.windowUUID + ", removing tracking");
                }
                delete childWindows[childWindowUUID];
            }
        });

        try {
            if (parentWindow && (incomingEventWindowUUID !== parentWindow.AlphaEventService.windowUUID)) {
                if (svc.debug) {
                    console.log("EVT_SVC: sending global::" + topic + " from window " + svc.windowUUID + " to parent " + parentWindow.AlphaEventService.windowUUID);
                }
                parentWindow.AlphaEventService.publishGlobal(topic, data, svc.windowUUID);
            }
        } catch (e) {
            if (svc.debug) {
                console.log("EVT_SVC: parent window is inaccessible from window " + svc.windowUUID + ", removing tracking");
            }
            parentWindow = null;
        }
    }

    // helper for publishing an event async and executing it within an angular context
    function asyncPublish(subscriber, topic, data) {
        var phase;

        if (subscriber.scope.$root) {
            phase = subscriber.scope.$root.$$phase;
        } else {
            return;
        }

        if (phase !== '$apply' && phase !== '$digest') {
            subscriber.scope.$applyAsync(function () {
                subscriber.callback(data, { 'topic': topic });
            });
        } else {
            setTimeout(function () {
                var _phase;
                if (subscriber.scope.$root) {
                    _phase = subscriber.scope.$root.$$phase;
                }
                if (_phase == '$apply' || _phase == '$digest') {
                    subscriber.callback(data, { 'topic': topic });
                } else {
                    subscriber.scope.$apply(function () {
                        subscriber.callback(data, { 'topic': topic });
                    });
                }
            }, 0);
        }
    }

    function unsubscribeChildWindowImpl(child) {
        var uuid = _.get(child, 'AlphaEventService.windowUUID');
        if (!_.isEmpty(uuid)) {
            delete childWindows[uuid];
        }
    }

    // track subscribers by their scope - helper for subscribeFn
    function trackScopeSubscriber(name, scope, channel, topics) {
        // create an unsubscribe hook if it doesn't already exist
        if (!scope.EventsUnsubscriberAttached) {
            scope.$on('$destroy', function() {
                unsubscribeScope(scope);
            });
            scope.EventsUnsubscriberAttached = true;
        }

        // track our subscriptions for the unsubscriber to process
        var scopeSubs = scopeSubscribers[scope.$id];
        if (!scopeSubs) {
            scopeSubs = scopeSubscribers[scope.$id] = [];
        }
        scopeSubs.push({ channel: channel, topics: topics, name: name });
    }

    // helper for subscribeFn() to subscribe to a topic
    function subscribeToTopic(name, scope, channelSubs, topic, callback) {
        if (topic === 'all') {
            angular.forEach(channelSubs, function(_subs, _topic) {
                if (_subs[name]) {
                    throw Error("Subscriber [" + name + "] is trying to listen for `all` events but is already listening to `" + _topic + "`.");
                }
            });
        } else {
            if (channelSubs['all'] && channelSubs['all'][name]) {
                throw Error("Subscriber [" + name + "] is trying to listen for `" + topic + "` events but is already listening to `all`.");
            }
        }

        var topicSubs = channelSubs[topic];
        if (!topicSubs) {
            topicSubs = channelSubs[topic] = {};
        }

        topicSubs[name] = { scope: scope, callback: callback };
    }

    // Remove all listeners when a scope is destroyed
    function unsubscribeScope(scope) {
        angular.forEach(scopeSubscribers[scope.$id], function(deleteInfo) {
            delete subscriberNames[deleteInfo.name];
            var chanSubs = subscribers[deleteInfo.channel];
            if (chanSubs) {
                if (angular.isArray(deleteInfo.topics)) {
                    angular.forEach(deleteInfo.topics, function(topic) {
                        deleteSubFromTopic(chanSubs, topic, deleteInfo.name);
                    });
                } else {
                    deleteSubFromTopic(chanSubs, deleteInfo.topics, deleteInfo.name);
                }

                // delete the channel if it now has zero topics with subscribers
                if (jQuery.isEmptyObject(chanSubs)) {
                    delete subscribers[deleteInfo.channel];
                }
            }
        });

        delete scopeSubscribers[scope.$id];

        if (svc.debug) { console.log("EVT_SVC: unsubscribed scope " + scope.$id); }
    }

    // helper for unsubscribes to delete a subscriber from a topic
    function deleteSubFromTopic(chanSubs, topic, name) {
        var topicSubs = chanSubs[topic];
        if (topicSubs) {
            delete topicSubs[name];

            // delete the topic if it now has zero subscribers
            if (jQuery.isEmptyObject(topicSubs)) {
                delete chanSubs[topic];
            }
        }
    }

    function printSubs() {
        if (!svc.debug) { return; }

        console.groupCollapsed("EVT_SVC: subscriptions on " + svc.windowUUID + " (" + window.location.href + ")");
        angular.forEach(subscribers, function(chanSubs, chanName) {
            console.group(chanName + ":");
            angular.forEach(chanSubs, function(topicSubs, topicName) {
                console.group(topicName + ":");
                angular.forEach(topicSubs, function (sub, subName) {
                    console.log(subName + ": scope " + sub.scope.$id);
                });
                console.groupEnd();
            });
            console.groupEnd();
        });
        console.groupEnd();
    }

    function _getRandomId() {
        var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
            result, id;
        do {
            result = [];
            _.times(8, function() {
                result.push(chars.charAt(Math.floor(Math.random() * chars.length)));
            });
            id = result.join('');
        } while (subscriberNames[id]);
        return id;
    }

    return {
        subscribe: subscribeFn,
        subscribeGlobal: subscribeGlobalFn,
        unsubscribe: unsubscribeFn,
        unsubscribeGlobal: unsubscribeGlobalFn,
        publish: publishFn,
        publishGlobal: publishGlobalFn,
        unsubscribeChildWindow: unsubscribeChildWindowFn
    };
}]);

/**
 * A module that provides an HTTP Interceptor that will listen for HTTP 403 return codes.  If this code is
 * encountered, a modal login dialog is popped up to get the user to re-authenticate.
 *
 * This module should not be interacted with, but rather set up default actions on the $http object.
 */
angular.module('alpha.utils.AuthenticationUtils', ['alpha.utils.Utils', 'alpha.login.Login', 'ui.bootstrap.modal', 'alpha.utils.Loader','alpha.utils.HttpInterceptor'])
    .config(['$httpProvider', function($httpProvider) {
        var interceptor = ['$rootScope','$q', '$log', '$injector', '$cookies', '$window', function(scope, $q, $log, $injector, $cookies, $window) {

            var requestQueue = [];

            var successFn = function(response) {
                return response;
            };

            var errorFn = function(response) {
                var status = response.status,
                    ssoCookie = $cookies.get('SSOCOOKIE');

                if (status === 401 && !ssoCookie) {
                    /* Using $injector avoids circular dependencies, i.e. LoaderService
                        requires I18nUtil which requires services that use $http, which
                        ends up with this interceptor's dependencies. */
                    var LoaderService = $injector.get('LoaderService'),
                        $uibModal = $injector.get('$uibModal'),
                        $http = $injector.get('$http'),
                        UserPreferences = $injector.get('UserPreferences');
                    // Remove the loading screen state
                    var loaderCount = LoaderService.getCount();
                    LoaderService.setCount(0);
                    LoaderService.stopLoading();

                    var deferredRequest = {
                        config: response.config,
                        deferred: $q.defer()
                    };
                    requestQueue.push(deferredRequest);
                    if(!scope.modalDialogOpen) {
                        scope.modalDialogOpen = true;
                        UserPreferences.cognosLogout();
                        UserPreferences.deleteCognosCookies();

                        var d = $uibModal.open({
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'modal-default login-modal',
                            backdropClass: 'timeout-login-modal',
                            templateUrl: applicationContextRoot + '/static/custom/common/login/loginModal.html',
                            controller: 'LoginModalController',
                            controllerAs: 'lmCtrl'
                        });

                        d.result.then(function () {
                            LoaderService.setCount(loaderCount);
                            requestQueue.forEach(function (deferredRequest) {
                                $http(deferredRequest.config).then(function (deferredResponse) {
                                    deferredRequest.deferred.resolve(deferredResponse);
                                });
                            });
                            scope.modalDialogOpen = false; //If session times out again, we have to show the dialog again.
                            LoaderService.setCount(0);

                        });
                    }
                    return deferredRequest.deferred.promise;
                }else if(status === 401 && !!ssoCookie){
                   $window.location = atob(ssoCookie);
                }

                // otherwise
                return $q.reject(response);
            };

            return  {
                response: successFn,
                responseError: errorFn
            };
        }];

        $httpProvider.interceptors.push(interceptor);
    }]);

angular.module('alpha.utils.HttpInterceptor',[]).
config(['$httpProvider',function($httpProvider){
    $httpProvider.interceptors.push(interceptorFn);

    function interceptorFn(){
        return {
            request: function(config) {
                if(config.headers){
                    config.headers['X-Timezone'] = moment.tz.guess();
                    config.headers['X-Timezone-Offset'] = new Date().getTimezoneOffset();
                }
                return config;
            }
        };
    }
}]);

(function () {
    'use strict';
    angular
        .module('alpha.utils.DynaTree', ['alpha.utils.Utils', 'UserPreferences'])
        .factory('DynaTreeService', ['Utils',DynaTreeService]);

    function DynaTreeService(Utils) {
        var treeLookup = {};
        var getFullPathForTree = function (tree) {
            var path = tree.data.key;
            if (tree.parent && tree.parent.parent) {
                path = getFullPathForTree(tree.parent) + "." + path; // recursive call
            }
            return path;
        };
        var getFullPathForIdFn = function (id) {
            var fullPath = "";
            if (treeLookup[id]) {
                fullPath = getFullPathForTree(treeLookup[id]);
            }
            return fullPath;
        };
        var setTreeForIdFn = function (payload) {
            treeLookup[payload.divId] = payload.node;
        };
        var getTreeForIdFn = function (id) {
            var tree = {};
            if (treeLookup[id]) {
                tree = treeLookup[id];
            }
            return tree;
        };

        function mapDynaTree(tree) {
            var node = _mapRoot(tree);
            if(tree.children){
                node.children = _mapChildren(tree);
            }
            return node;

            function _mapRoot(object) {
                return {
                    key: object.id,
                    recordType: object.value.recordType,
                    title: Utils.sanitize(object.value.recordTypeDescription),
                    isLazy: _getIsLazy(object),
                    icon: false
                };
            }

            function _mapChildren(object) {
                return _.forEach(object.children, function (child, index) {
                    object.children[index] = mapDynaTree(child);
                });
            }

            function _getIsLazy(object){
                return  object.children ? true : false;
            }
        }

        return {
            setTreeForId: setTreeForIdFn,
            getTreeForId: getTreeForIdFn,
            getFullPathForId: getFullPathForIdFn,
            mapDynaTree: mapDynaTree
        };
    }
})();

(function () {
    'use strict';
    angular
        .module('alpha.utils.RequiredFieldUtils',[])
        .factory('RequiredFieldService', RequiredFieldService);

    function RequiredFieldService() {
        var REQUIRED_CLASS = 'field-required';
        return {
            enableRequiredClass: enableRequiredClass,
            disableRequiredClass: disableRequiredClass,
            isRequiredFieldValid: isRequiredFieldValid
        };

        function enableRequiredClass(element) {
            element.addClass(REQUIRED_CLASS);
        }
        function disableRequiredClass(element) {
            element.removeClass(REQUIRED_CLASS);
        }
        function isRequiredFieldValid(value) {
            return !(_.isUndefined(value) || _.isNull(value) || _isEmptyString(value) || _isEmptyObject(value));

            function _isEmptyString(val){
                return _.isString(val) && (_.isEmpty(val) || _.isEmpty(val.trim()));
            }
            function _isEmptyObject(val) {
                return _.isObject(val) && _.isEmpty(val);
            }
        }
    }
})();


(function() {
    'use strict';
    angular
        .module('alpha.utils.LogOutAction', [])
        .factory('LogOutAction', LogOutAction);
    LogOutAction.$inject = ['$window'];
    function LogOutAction($window) {
        var active = false;
        logoutListener();
        return {
            logoutListener:logoutListener
        }
        function logoutListener() {
            if(!active){
                $window.addEventListener('storage', storageEventHandler);
                active = true;
            }
            function storageEventHandler(evt){
                if (evt.key === 'logOut' && evt.oldValue === null && evt.newValue === 'Y'){
                    $window.top.location = applicationContextRoot + '/j_spring_security_logout';
                }
            }
        }
    }
})();
