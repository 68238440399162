(function() {
    'use strict';

    angular
        .module('alpha.common.directives.recentRecordPopOver', [])
        .directive('recentRecordPopOver', recentRecordPopOver);

        recentRecordPopOver.$inject = ['$timeout'];

        function recentRecordPopOver($timeout) {
            return {
                restrict: 'A',
                scope: {
                    record: '=popRecord',
                },
                link: link
            };
        function link(scope, element) {
            scope.mousehover= false;
            scope.popoveractivated = false;
            scope.$watch('record', function(updatedRecord,oldvalue) {
                if(updatedRecord && updatedRecord.toolTipData && !scope.popoveractivated){
                        var popoverOptions = getPopOverTemplate(updatedRecord);
                        element.popover(popoverOptions);
                        if(scope.mousehover){
                            element.popover('toggle');
                        }
                        scope.popoveractivated = true;
                } else if(oldvalue && oldvalue.toolTipData && !updatedRecord.toolTipData && scope.popoveractivated){
                    scope.popoveractivated = false;
                    element.popover('destroy');
                } else if((oldvalue && oldvalue.toolTipData && updatedRecord.toolTipData && updatedRecord.id !== oldvalue.id && scope.popoveractivated)){
                    element.popover('destroy');
                    $timeout(function (){
                        var popoverOptions = getPopOverTemplate(updatedRecord);
                        element.popover(popoverOptions);
                        if(scope.mousehover){
                            element.popover('toggle');
                        }
                    },300);
                }
            },true);
            element.bind('mouseenter',function() {
                scope.mousehover = true;
            });
            element.bind('mouseleave',function() {
                scope.mousehover = false;
            });
        }

        function getPopOverTemplate(recentRecord) {
            var popoverTemplate = '<div id="recentPopover" class="popover recentrecords-popover">' +
                '<div class="arrow"></div>' +
                '<div class="popover-content"></div>' +
                '</div>';
            return {
                html: true,
                template: popoverTemplate,
                content: getPopOverContent(recentRecord),
                delay: 0,
                placement: getPlacement,
                trigger: 'hover',
                container: 'body',
            };

            function getPlacement(context, source) {
                var poz = $(window).scrollTop() + $(window).height() / 2;
                var position = $(source).offset().top;

                if ((position + $(source).outerHeight()) < poz) {
                    return 'bottom';
                }
                return 'top';
            }

            function getPopOverContent(recentRecord) {
                var data = '';
                for (var i = 0; i < recentRecord.toolTipData.length; i++) {

                    data = data + '<div class="row"> ' +
                        '<strong><div class="col-key col-md-6 truncate">' + recentRecord.toolTipData[i].keyDescription + '</div></strong>' +
                        '<div class="col-val col-md-6 truncate">' + recentRecord.toolTipData[i].value + '</div>' +
                        '</div>';
                }
                var popOverContent = ['<div class="recentrecords-popover-content">',
                    '<div class="container-fluid">',
                    data,
                    '</div></div></div>'
                ].join('');
                return popOverContent;
            }
        }
    }
})();
