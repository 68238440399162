(function() {
    'use strict';

    /**
     * Adds a control that can lock or unlock a column in a Smart Table.
     * Locked columns must have widths defined in pixels for the rest of
     * the table to be displayed correctly.
     *
     * @method alphaSmartTableColumnLock
     *
     * @example
     *      HTML:
     *      <alpha-smart-table-column-lock
     *          column="someGridColumn"
     *          columns="someGridConfig.columns"></alpha-smart-table-column-lock>
     *
     * @param {Object} column The column to lock or unlock, in the format: {width: '', locked: true}
     * @param {Number} column.ordinal The ordinal of this column
     * @param {String} [column.width] The width of this column; set to a pixel value when locked
     * @param {Boolean} [column.locked] Whether this column is locked; toggled by this control
     * @param {Object[]} columns The full column configuration for the smart table
     * @param {Number} columns.ordinal The ordinal of each column
     * @param {Number} [columns.locked] Whether each column is locked
     */

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableColumnLock', alphaSmartTableColumnLock);

    var template = '' +
        '<span ng-if="isLockable()">' +
        '    <span ng-hide="column.locked" ng-click="lock($event)" class="ventiv-icon icon-ic_unlock alpha-smart-table-lock-column"></span>' +
        '    <span ng-show="column.locked" ng-click="unlock($event)" class="ventiv-icon icon-ic_lock alpha-smart-table-unlock-column"></span>' +
        '</span>';

    function alphaSmartTableColumnLock() {
        return {
            link: link,
            restrict: 'E',
            template: template,
            scope: {
                column: '=',
                columns: '='
            }
        };
        function link(scope, element) {
            var heading = element.closest('.alpha-table-heading');

            scope.lock = lock;
            scope.unlock = unlock;
            scope.isLockable = isLockable;
            scope.isUnlockable = isUnlockable;

            function lock($event) {
                $event.stopPropagation();
                if (scope.isLockable()) {
                    if (!_.includes(scope.column.width, 'px')) {
                        scope.column.width = heading.outerWidth() + 'px';
                    }
                    scope.column.locked = true;
                }
            }
            function unlock($event) {
                $event.stopPropagation();
                if (scope.isUnlockable()) {
                    scope.column.locked = false;
                }
            }
            function isLockable() {
                var precedingColumn = _.maxBy(_.filter(scope.columns, function(column) {
                    return column.visible && column.ordinal < scope.column.ordinal;
                }), 'ordinal');
                return _.isUndefined(precedingColumn) || precedingColumn.locked === true;
            }
            function isUnlockable() {
                var followingColumn = _.minBy(_.filter(scope.columns, function(column) {
                    return column.visible && column.ordinal > scope.column.ordinal;
                }), 'ordinal');
                return _.isUndefined(followingColumn) || !followingColumn.locked;
            }
        }
    }
})();
