(function() {
    'use strict';

    /**
     * A directive wrapper for select2 dropdowns, that are bound to the Lookup Library concept.
     *
     * This directive only works with model values that are valid selections in a specific format,
     * and external controllers are responsible for setting them accordingly. If the model value
     * is an object, its id and value properties will be used. If the model value is a string, both
     * of those properties will be set to the string value.
     *
     * @method aonLookup
     *
     * @example
     *      HTML:
     *      <input id="FieldList"
     *          ng-model="searchRequest.fieldList"
     *          record-type="recordTypeId"
     *          event-context="some_context"
     *          event-record-type="some_record_type"
     *          event-field-type="some_field_type"
     *          read-only-mode="true"
     *          aon-lookup
     *          aon-lookup-placeholder="Please Select..."
     *          aon-lookup-library="RecordTypeFields"
     *          aon-lookup-static-options="someCtrl.staticOptions"
     *          aon-lookup-static-options-legacy-behavior="true"
     *          aon-lookup-filter="filterName"
     *          aon-lookup-filter-model="scope.property"
     *          aon-lookup-filter-2="additionalFilterName"
     *          aon-lookup-filter-model-2="additional.scope.property"
     *          aon-lookup-hide-clear-button="true"
     *          aon-lookup-multiple="true"
     *          aon-lookup-sortable="true"
     *          aon-lookup-tree-flatten="false"
     *          aon-lookup-preserve-values="true"
     *          aon-lookup-scope-set-property="id"
     *          aon-lookup-scope-set-transformer="transformerFunction"
     *          aon-lookup-selection-formatter="formatterFunction"
     *          aon-lookup-result-formatter="formatterFunction"
     *          aon-lookup-result-disabled="disablerFunction"
     *          aon-lookup-identifier="identifierFunction"
     *          aon-lookup-sorter="sorterFunction"
     *          aon-lookup-matcher="matcherFunction"
     *          aon-lookup-sort="ASC"
     *          aon-lookup-correlated-rec-type="recordTypeId"
     *          aon-lookup-correlated-icon-id="iconId"
     *          aon-lookup-cache-library="false">
     *
     * @param {String} id Unique ID of the element
     * @param {Object} ng-model Model to bind this dropdown to
     * @param {Object} aon-lookup-library ID of the Lookup Library that contains the data for this dropdown.
     * @param {String} [aon-lookup-filter] Special lookup filter parameter name
     * @param {Object} [aon-lookup-filter-model] Special lookup filter parameter value (a property on the scope that can be watched)
     * @param {String} [aon-lookup-filter-2...] Additional filters; any number can be included
     * @param {Object} [aon-lookup-filter-model-2...] Additional filter models; any number can be included
     * @param {lookupValueFormatter} [aon-lookup-selection-formatter] Formats lookup values for display in the selection; defaults to the value property
     * @param {lookupValueFormatter} [aon-lookup-real-time-selection-formatter] Formats lookup values for display in the selection; updates in real time rather than only when the model value changes
     * @param {lookupValueFormatter} [aon-lookup-result-formatter] Formats lookup values for display in the results; defaults to the value property
     * @param {lookupValueDisabler} [aon-lookup-result-disabled] Disables an option when it returns true
     * @param {Object[]} [aon-lookup-static-options] Static options to be prepended when loading a remote library, or to be used instead of a library if no library is specified.
     * @param {String} aon-lookup-static-options.id Option ID, i.e. a service name.
     * @param {String} aon-lookup-static-options.value Option display value.
     * @param {Boolean} [aon-lookup-static-options-legacy-behavior] When true, every time options are loaded the model will be set as if the user chose a value. This is not a standard behavior.
     * @param {Boolean} [aon-lookup-multiple] Should multiple's be allowed to be selected
     * @param {Boolean} [aon-lookup-sortable] Enable manual sorting on the field.  Requires "multiple" (aon-lookup-multiple='true') and jQuery-UI (JS) to be included
     * @param {Boolean} [aon-lookup-tree-flatten] Should the tree be flattened when searching / filtering this dropdown, or should the path to the root of the tree be included.  Defaults to true.
     * @param {Boolean} [aon-lookup-hide-clear-button] Whether to hide the button that clears the selected value. Defaults to false.
     * @param {Boolean} [aon-lookup-preserve-values] Preserves values when parsing or formatting; use when options and model values are identical. Preferred over the old ID/VALUE paradigm.
     * @param {String} [aon-lookup-scope-set-property] Name of or path to a property to pull from the lookup value and set to the model, i.e. "id" for lookups which need to be saved as strings.
     * @param {Function} [aon-lookup-scope-set-transformer] A method that will transform the values that are selected before setting into the scope ng-model.
     * @param {String} [aon-lookup-sort] If supplied, either 'ASC' or 'DESC'.  Otherwise values are returned in whatever order the DB returns them in.
     * @param {String} [aon-lookup-correlated-rec-type] The correlated recordtype if this is a correlated LL.
     * @param {String} [aon-lookup-correlated-icon-id] The ID of the icon for the correlated recordtype.
     * @param {String} [aon-lookup-placeholder] Placeholder text when there is no value selected.
     * @param {String} [aon-lookup-load-local-data] If specified, the LookupInterface.getLookupLibraryDetails  will not be called
     * @param {Function} [aon-lookup-identifier] If specified, overrides default use of the ID property to identify options. Useful for cases when ID is not unique.
     * @param {Function} [aon-lookup-sorter] If specified, overrides the default (or specified) sort order.
     * @param {Function} [aon-lookup-matcher] If specified, overrides default startsWith matcher when searching queried options.
     * @param {Boolean} [aon-lookup-cache-library] Whether the lookup library will be cached so it doesn't load every time the dropdown is opened. Defaults to true.
     * @param {String} [recordType] Record type the field belongs to
     * @param {String} [eventContext] Context for the field to listen to (rules, formulas, etc.)
     * @param {String} [eventRecordType] Record type for the field to listen to (rules, formulas, etc.)
     * @param {String} [eventFieldType] Field type for the field to listen to (rules, formulas, etc.)
     * @param {Boolean} [read-only-mode] Makes the input permanently read only even if a rule makes it writable.
     *
     * @callback lookupValueFormatter
     *
     * @param {*} value The lookup value in the format of a model value
     * @param {String} lookupLibraryId Service name of the lookup library
     *
     * @callback lookupValueDisabler
     *
     * @param {*} value The lookup value in the format of a model value
     */

    angular
        .module('alpha.directives.aonLookup', [
            'AlphaApi',
            'UserPreferences',
            'alpha.utils.Utils',
            'alpha.utils.I18n',
            'alpha.utils.recordData',
            'alpha.utils.RequiredFieldUtils',
            'alpha.utils.Events',
            'alpha.common.services.alphaTabManager',
            'alpha.common.services.ruleExecutionHelper'
        ])
        .directive('aonLookup', aonLookup);

    aonLookup.$inject = [
        'LookupInterface',
        'UserPreferences',
        'I18nUtil',
        'RecordDataUtil',
        'RequiredFieldService',
        'Events',
        'AlphaTabManagerService',
        'RuleExecutionHelper',
        'Utils'
    ];

    function aonLookup(
        LookupInterface,
        UserPreferences,
        I18nUtil,
        RecordDataUtil,
        RequiredFieldService,
        Events,
        AlphaTabManagerService,
        RuleExecutionHelper,
        Utils
    ) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };

        function link(scope, element, attrs, ngModelCtrl) {
            var scopeSetterFn = attrs.aonLookupScopeSetProperty ? _getPropFromLookupValue : scope.$eval(attrs.aonLookupScopeSetTransformer),
                config, _readOnlyMode, _readOnlyState;

            ngModelCtrl.$formatters.shift();  // remove the default ngModel modelValue->viewValue to string formatter (new in angular-1.3)
            config = {
                multiple: scope.$eval(attrs.aonLookupMultiple),
                iconId: attrs.aonLookupCorrelatedIconId
            };
            _initSelect2();
            _handleUiSortable();
            _handleAttributes();
            _handleEvents();
            _toggleCorrelatedIconDisabled(true);
            ngModelCtrl.$formatters.push(config.multiple ? _multiFormatter : _singleFormatter);
            ngModelCtrl.$parsers.unshift(config.multiple ? _multiParser : _singleParser);
            ngModelCtrl.$render = _render;
            // The model value is an object, so it needs to be deep watched
            scope.$watch(function() {
                return ngModelCtrl.$modelValue;
            }, _getModelValue, true);

            function _initSelect2() {
                var identifierCallback = scope.$eval(attrs.aonLookupIdentifier),
                    sorterCallback = scope.$eval(attrs.aonLookupSorter),
                    selectionFormatterCallback = _getSelectionFormatter(),
                    select2opts = {
                        minimumInputLength: 0,
                        placeholder: attrs.aonLookupPlaceholder || I18nUtil.getI18nString('LBL_CHOOSE_VALUE', 'Choose a Value'),
                        multiple: config.multiple,
                        formatSelection: selectionFormatterCallback,
                        formatResult: _getResultFormatter(),
                        formatSearching: I18nUtil.getI18nString('MSG_LOADING', 'Loading') + '\u2026',
                        formatNoMatches: I18nUtil.getI18nString('MSG_NO_MATCHES_FOUND', 'No matches found'),
                        minimumResultsForSearch: 0,
                        matcher: scope.$eval(attrs.aonLookupMatcher) || DEFAULT_MATCHER,
                        query: _select2Query,
                        allowClear: attrs.aonLookupHideClearButton !== 'true'
                    };
                if (_.isFunction(identifierCallback)) {
                    select2opts.id = identifierCallback;
                }
                if (_.isFunction(sorterCallback)) {
                    select2opts.sortResults = sorterCallback;
                }
                if (_.isFunction(scope.$eval(attrs.aonLookupRealTimeSelectionFormatter))) {
                    scope.$watch(function() {
                        return selectionFormatterCallback(ngModelCtrl.$viewValue, attrs.aonLookupLibrary);
                    }, function(newVal) {
                        _setSelectionText(newVal);
                    });
                }
                element.select2(select2opts);
            }
            function _getSelectionFormatter() {
                var optionalFormatter = scope.$eval(attrs.aonLookupSelectionFormatter) || scope.$eval(attrs.aonLookupRealTimeSelectionFormatter);
                if (_.isFunction(optionalFormatter)) {
                    return function(value) {
                        return Utils.sanitize(optionalFormatter(value, attrs.aonLookupLibrary));
                    };
                } else {
                    return DEFAULT_SELECTION_FORMATTER;
                }
            }
            function _getResultFormatter() {
                var optionalFormatter = scope.$eval(attrs.aonLookupResultFormatter);
                if (_.isFunction(optionalFormatter)) {
                    return function(value) {
                        return Utils.sanitize(optionalFormatter(value, attrs.aonLookupLibrary));
                    };
                } else {
                    return DEFAULT_RESULT_FORMATTER;
                }
            }
            function _handleUiSortable() {
                var select2Container = element.select2('container');
                // Handle the Sortable - Attach to the container's UL
                if (attrs.aonLookupSortable === 'true' && select2Container.sortable) {
                    select2Container.find('ul.select2-choices').sortable({
                        containment: 'parent',
                        start: function() { element.select2('onSortStart'); },
                        update: function() { element.select2('onSortEnd'); }
                    });
                }
            }
            function _handleAttributes() {
                attrs.$observe('id', function() {
                    // allows for dynamic IDs that change after Select2 initializes
                    element.prev('.select2-container').attr('id', 's2id_' + attrs.id);
                });
                attrs.$observe('disabled', function(value) {
                    if (_.isBoolean(value)) {
                        element.select2('enable', !value);
                    }
                });
            }
            function _toggleCorrelatedIconDisabled(disabled) {
                $('#' + config.iconId).prop('disabled', disabled || scope.recordCanBeRead && !scope.recordCanBeRead(attrs.aonLookupCorrelatedRecType));
            }
            function _handleEvents() {
                RuleExecutionHelper.subscribeToFieldEvents(scope, ['setRequired', 'setReadOnly'], attrs, _eventHandler);
                attrs.$observe('readOnlyMode', function(newVal) {
                    if (newVal === 'true') {
                        _readOnlyMode = true;
                        _enableReadOnly();
                    } else {
                        _readOnlyMode = false;
                        if (!_readOnlyState) {
                            _disableReadOnly();
                        }
                    }
                    _updatePlaceHolderText();
                });
                if (attrs.aonLookupStaticOptionsLegacyBehavior === 'true' && _.isEmpty(attrs.aonLookupLibrary)) {
                    scope.$watch(attrs.aonLookupStaticOptions, function(newVal) {
                        if (_.isArray(newVal)) {
                            _setValueFromOptions(newVal);
                        }
                    });
                }
                // Dropdown selection makes correlated link icon clickable
                $('#' + config.iconId).click(function() {
                    openRecordInTab();
                });
            }
            function _eventHandler(data, event) {
                switch (event.topic) {
                    case 'setRequired':
                        _setRequired();
                        break;
                    case 'setReadOnly':
                        _setReadOnly();
                        break;
                }
                function _setRequired() {
                    if (data.value === true) {
                        ngModelCtrl.$validators.required = RequiredFieldService.isRequiredFieldValid;
                        ngModelCtrl.$setValidity('required', RequiredFieldService.isRequiredFieldValid(ngModelCtrl.$modelValue));
                    } else if (data.value === false) {
                        ngModelCtrl.$setValidity('required', true);
                        delete ngModelCtrl.$validators.required;
                    }
                }
                function _setReadOnly() {
                    if (data.value === true) {
                        _readOnlyState = true;
                        _enableReadOnly();
                    } else if (data.value === false) {
                        _readOnlyState = false;
                        if (!_readOnlyMode) {
                            _disableReadOnly();
                        }
                    }
                }
            }
            function _enableReadOnly() {
                element.select2('readonly', true);
            }
            function _disableReadOnly() {
                element.select2('readonly', false);
            }
            function _setValueFromOptions(options) {
                var selectedValue = null;
                if (ngModelCtrl.$viewValue && ngModelCtrl.$viewValue.id) {
                    selectedValue = _findInOptions(ngModelCtrl.$viewValue.id);
                }
                ngModelCtrl.$setViewValue(selectedValue);
                ngModelCtrl.$render();
                function _findInOptions(id) {
                    var option = _.find(options, {id: id});
                    return _.isObject(option) ? {id: option.id, value: option.value} : null;
                }
            }
            function _singleFormatter(modelValue) {
                var inputValue, viewValue;
                if (attrs.aonLookupPreserveValues === 'true') {
                    viewValue = angular.copy(modelValue);
                } else {
                    if (!_.isEmpty(modelValue) || _.isFinite(modelValue)) {
                        inputValue = element.select2('data');
                        if (attrs.aonLookupScopeSetProperty && !config.multiple && inputValue && modelValue === _.get(inputValue, attrs.aonLookupScopeSetProperty)) {
                            /* Don't change the view value if it already matches the incoming model. This prevents callbacks
                                like the result formatter from running unnecessarily and with incomplete arguments. */
                            viewValue = ngModelCtrl.$viewValue;
                        } else if (_.isString(modelValue) || _.isFinite(modelValue)) {
                            viewValue = {
                                id: modelValue,
                                value: modelValue
                            };
                        } else if (_.isObject(modelValue)) {
                            viewValue = angular.copy(modelValue);
                        }
                    }
                }
                return viewValue;
            }
            function _multiFormatter(modelValue) {
                var viewValue;
                if (_.isArray(modelValue)) {
                    viewValue = _.map(modelValue, _singleFormatter);
                    _.pull(viewValue, undefined);
                }
                return viewValue;
            }
            function _singleParser() {
                // The real $viewValue is changed by Select2, so this uses Select2 data instead
                var viewValue = element.select2('data'),
                    modelValue;
                if (attrs.aonLookupPreserveValues === 'true') {
                    modelValue = viewValue;
                } else {
                    modelValue = _.isFunction(scopeSetterFn) ? scopeSetterFn(viewValue) : viewValue;
                    if (_.isEmpty(modelValue) && !_.isFinite(modelValue)) {
                        modelValue = null;
                    } else if (_.has(modelValue, 'recordMasterId')) {
                        _toggleCorrelatedIconDisabled(modelValue.recordMasterId == null);
                    }
                }
                return angular.copy(modelValue);
            }
            function _multiParser() {
                // The real $viewValue is changed by Select2, so this uses Select2 data instead
                var viewValue = element.select2('data'),
                    modelValue;
                if (attrs.aonLookupPreserveValues === 'true') {
                    modelValue = viewValue;
                } else {
                    modelValue = _.isFunction(scopeSetterFn) ? _.map(viewValue, scopeSetterFn) : viewValue;
                }
                // TODO: Not sure how (or if) correlated icons should be handled here
                return _.isEmpty(modelValue) ? null : modelValue;
            }
            function _render() {
                element.select2('data', ngModelCtrl.$viewValue);
                if (_.has(ngModelCtrl.$viewValue, 'recordMasterId')) {
                    _toggleCorrelatedIconDisabled(ngModelCtrl.$viewValue.recordMasterId == null);
                }
                _updatePlaceHolderText();
            }
            function _getPropFromLookupValue(lookupValue) {
                return _.isObject(lookupValue) ? _.get(lookupValue, attrs.aonLookupScopeSetProperty) : lookupValue;
            }
            function _getModelValue() {
                ngModelCtrl.$modelValue = 0; // force the $formatters pipeline to run
            }
            /**
             * Opens a new view in the jqx tab based on the recordType and id.
             * Used by ViewCompilerUtil-generated LL dropdowns if they are a correlated lookup library.
             */
            function openRecordInTab() {
                UserPreferences.getClientId().then(openTab);
                function openTab(clientId) {
                    AlphaTabManagerService.addTab(
                        'correlated-record-link-' + config.iconId,
                        attrs.aonLookupCorrelatedRecType,
                        RecordDataUtil.getRecordDetailUrl(clientId, attrs.aonLookupCorrelatedRecType, ngModelCtrl.$viewValue.recordMasterId)
                    );
                }
            }
            function _select2Query(queryOptions) {
                var flatten = attrs.aonLookupTreeFlatten === 'false' ? false : true,
                    sort = attrs.aonLookupSort || 'CUSTOM',
                    filteringCriteriaNames = [],
                    filteringCriteriaValues = [];
                _.forIn(attrs, function(attrValue, attrName) {
                    var filteringCriteriaName, filteringCriteriaValue;
                    if (_.startsWith(attrName, 'aonLookupFilterModel')) {
                        filteringCriteriaName = attrs[attrName.replace('aonLookupFilterModel', 'aonLookupFilter')];
                        filteringCriteriaValue = scope.$eval(attrValue);
                        filteringCriteriaValue = filteringCriteriaValue === null || filteringCriteriaValue === undefined ? '' : filteringCriteriaValue;
                        filteringCriteriaNames.push(filteringCriteriaName);
                        filteringCriteriaValues.push(_.get(filteringCriteriaValue, 'id') || filteringCriteriaValue);
                    }
                });
                if (_.isEmpty(attrs.aonLookupLibrary)) {
                    window.Select2.query.local({
                        text: 'value',
                        results: scope.$eval(attrs.aonLookupStaticOptions) || []
                    })(queryOptions);
                } else {
                    UserPreferences.getClientId().then(_getLookupLibraryDetails);
                }
                function _isFlattenedTree(lookup) {
                    return lookup.lookupLibrary.lookupType === 'Tree' && attrs.aonLookupTreeFlatten === 'true';
                }
                function _getLookupLibraryDetails(clientId) {
                    if (attrs.aonLookupCacheLibrary === 'false') {
                        LookupInterface.getLookupLibraryDetails(
                            clientId,
                            attrs.aonLookupLibrary,
                            '',
                            filteringCriteriaNames,
                            filteringCriteriaValues,
                            flatten,
                            sort,
                            null,
                            false,
                            attrs.aonLookupIncludeExpired === 'true',
                            _successCallback,
                            _errorCallback
                        );
                    } else {
                        LookupInterface.getLookupLibraryDetailsFromCache(
                            clientId,
                            attrs.aonLookupLibrary,
                            '',
                            filteringCriteriaNames,
                            filteringCriteriaValues,
                            flatten,
                            sort,
                            null,
                            false,
                            attrs.aonLookupIncludeExpired === 'true',
                            _successCallback,
                            _errorCallback
                        );
                    }
                }
                function _successCallback(lookupResource) {
                    var isDisabled = scope.$eval(attrs.aonLookupResultDisabled),
                        staticOptions = scope.$eval(attrs.aonLookupStaticOptions),
                        originalData = [];
                        if (_.isArray(lookupResource.lookupLibrary.value)) {
                            originalData = lookupResource.lookupLibrary.value;
                        } else if (_.isObject(lookupResource.lookupLibrary.value)) {
                            originalData = [lookupResource.lookupLibrary.value];
                        }
                        // Check if lookup field security needs to be applied
                        if(_.includes(filteringCriteriaNames, 'fieldType')){
                            _applyLookupSecurity(originalData);
                        }
                        // If SubRecordTypes LL, remove elements the user cannot create
                        if (attrs.aonLookupLibrary === 'SubRecordTypes') {
                            _.remove(originalData, function(e) {
                                return e.canCreate !== 'true';
                            });
                        }
                        if (_.isArray(staticOptions)) {
                            originalData = staticOptions.concat(originalData);
                        }
                        if (_.isFunction(isDisabled)) {
                            _.forEach(originalData, function(result) {
                                if (isDisabled(result)) {
                                    result.disabled = true;
                                }
                            });
                        }
                        // Call the Select2 Local Query handler to process it like local data
                        window.Select2.query.local({ text: 'value', results: originalData })(queryOptions);

                    function _applyLookupSecurity(lookups){
                      _.forEach(lookups, function(lookup){
                          lookup.disabled = (_.has(lookup, 'readLookupAuthority') && !lookup.readLookupAuthority) || (_.has(lookup, 'updateLookupAuthority') && !lookup.updateLookupAuthority);
                          if(lookup.children){
                              _applyLookupSecurity(lookup.children);
                          }
                      });
                    }
                }
                function _errorCallback() {
                    scope.validationMessages = ['Fatal error, could not retrieve record data.'];
                }
            }
            function  _updatePlaceHolderText(){
                var placeHolderText ='';
                if(!_readOnlyMode){
                    placeHolderText = attrs.aonLookupPlaceholder || I18nUtil.getI18nString('LBL_CHOOSE_VALUE', 'Choose a Value');
                }
                if(!ngModelCtrl.$viewValue) {
                    _setSelectionText(placeHolderText);
                }
            }
            function _setSelectionText(text) {
                if (config.multiple) {
                    element.prev('.select2-container').find('.select2-search-choice div').html(text);
                } else {
                    element.prev('.select2-container').find('.select2-chosen').html(text);
                }
            }
            function DEFAULT_MATCHER(term, text) {
                return _.includes(_.toLower(text), _.toLower(term));
            }
            function DEFAULT_SELECTION_FORMATTER(value) {
                if (_lookupValueIsExpired(value)) {
                    return '<span>' + _getLookupDisplayValue(value) + '</span>&nbsp;<span>' + I18nUtil.getI18nString('LBL_LOOKUP_EXPIRED', '(Expired)' + '</span>');
                } else {
                    return _getLookupDisplayValue(value);
                }
            }
            function DEFAULT_RESULT_FORMATTER(value) {
                if (_lookupValueIsExpired(value)) {
                    return '<span class="glyphicon glyphicon-time expired-lookup-value"></span>&nbsp' + _getLookupDisplayValue(value) + '</span>';
                } else {
                    return _getLookupDisplayValue(value);
                }
            }
            function _getLookupDisplayValue(value) {
                return Utils.sanitize(_.escape(_.isObject(value) ? value.value : value));
            }
            function _lookupValueIsExpired(value) {
                return _.get(value, 'isExpired');
            }
        }
    }
})();
