(function () {
    'use strict';

    /**
     * A directive to control a paginated grid. By default, this directive
     * and everything else in the alphaSmartTable module is meant to work
     * with the Smart Table library. When server side mode is enabled, the
     * module works through a series of callbacks rather than Smart Table.
     * In either mode, the views, column configuration, and data binding
     * behave exactly the same way, and the outer component containing the
     * grid is responsible for managing the data. In server side mode, the
     * outer component is responsible for managing the state of the grid.
     *
     * @method alphaSmartTable
     *
     * @example
     *      HTML for Smart Table Mode:
     *      <div alpha-smart-table
     *          id="Unique_ID"
     *          st-table="someCtrl.grid.displayData"
     *          st-safe-src="someCtrl.grid.data"></detailed-mto>
     *
     * @example
     *      HTML for Server Side Mode:
     *      <div alpha-smart-table
     *          id="Unique_ID"
     *          ast-server-side-mode="true"
     *          ast-columns="someCtrl.grid.columns"
     *          ast-display-data="someCtrl.grid.data"
     *          ast-currency="someCtrl.grid.data[0]._currency"
     *          ast-charting-enabled="true"
     *          ast-chart-data="someCtrl.grid.chartData"
     *          ast-current-state="someCtrl.grid.state"
     *          ast-set-page-size="someCtrl.grid.setPageSize"
     *          ast-set-page-number="someCtrl.grid.setPageNumber"
     *          ast-set-sort-column="someCtrl.grid.setSortColumn"
     *          ast-set-download-filter="someCtrl.grid.setDownloadFilter"
     *          ast-set-server-side-search"someCtrl.grid.setServerSideSearch"
     *          ast-set-filter="someCtrl.grid.setFilter"
     *          ast-set-chart-config="someCtrl.grid.setChartConfig"></div>
     *
     * @param {String} id - Unique HTML ID.
     * @param {String} astServerSideMode - Whether to use a series of callbacks instead of Smart Table, "true" if enabled.
     * @param {Object[]} astColumns - Columns configured for the grid.
     * @param {String} astColumns.name - Unique name the column can be identified with.
     * @param {Boolean} [astColumns.highlighted] - Whether the column should be highlighted in the view.
     * @param {Object[]} astDisplayData - Ordered collection of results to display on the current page.
     * @param {Object} [astCurrency] - Primary currency to use in cases that require one, i.e. charts.
     * @param {String} [astCurrency.symbol] - Symbol to display when a single primary currency is needed.
     * @param {Boolean} astChartingEnabled - Whether chart controls should be made available to the user.
     * @param {Object} astChartData - Chart data to display, i.e. the results of a chart-enabled search.
     * @param {Object} astCurrentState - Current state of the grid as determined by the outer component.
     * @param {Number} astCurrentState.numberOfResults - Total number of results that can be paginated through.
     * @param {Number} astCurrentState.numberOfPages - Total number of pages that can be paginated through.
     * @param {Number} astCurrentState.currentPageNumber - Page number represented by the current display data.
     * @param {Number} astCurrentState.currentPageSize - Number of results to display per page.
     * @param {String} astSearchTerm - Search input text that can be passed to the grid.
     * @param {setPageSize} ast-set-page-size - Callback to be invoked when the user sets a page size.
     * @param {setPageNumber} ast-set-page-number - Callback to be invoked when the user goes to a page.
     * @param {setSortColumn} ast-set-sort-column - Callback to be invoked when the user sorts the grid.
     * @param {setFilter} ast-set-filter - Callback to be invoked when the user filters the grid.
     * @param {setChartConfig} ast-set-chart-config - Callback to be invoked when a chart is initialized.
     * @param {setFilterGrid} ast-set-filter-grid - Callback to be invoked when the user click on filter menu item.
     * @param {Object[]} ast-set-filter-menu - Object represent the Menu item to be display for filter
     * @param {filterMenuItemSelected} ast-clear-menu-selection - flag for clear the selected Menu item
     * @param {setServerSideSearch} ast-server-side-search - Callback to be invoked when the user uses the search input (can be used even in client side mode).
     * @param {setDownloadFilter} ast-set-download-filter - Callback to be invoked when filtering the table data for downloading
     * @param {setDownloadCallback} ast-set-download-callback - Callback to be invoked to return the data for downloading (to be use by the developer on the controller side)
     * @param {Boolean} astExcelUploadAuthority - Whether user will see  "Generate Spreadsheet for Upload" option or not.
     * @callback setPageSize
     *
     * @param {Number} pageSize Number of results to display per page
     *
     * @callback setPageNumber
     *
     * @param {Number} pageNumber Number of the page the user is going to, starting with 1
     *
     * @callback setSortColumn
     *
     * @param {Object} column Configuration object of the column the user is sorting
     *
     * @callback setFilter
     *
     * @param {String} columnName Name of the column being filtered
     * @param {String} operator Operator to be considered, i.e. EQUALS
     * @param {*} value Value being filtered
     *
     * @callback setChartConfig
     *
     * @param {Object} chartConfig Configuration object of the chart, in the standard API format
     *
     * @callback setServerSideSearch
     *
     * @param {String} value being used to search across the data
     *
     * @callback setDownloadFilter
     *
     */

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTable', alphaSmartTable);

    alphaSmartTable.$inject= ['$timeout'];

    function alphaSmartTable($timeout){
        return {
            restrict: 'A',
            controller: ['$scope', '$element', '$attrs', controller],
            link: link,
            controllerAs: 'astCtrl',
            scope: true
        };
        function controller($scope, $element, $attrs) {
            var self = this;
            self.$onInit = function() {
                controllerInit(self, $scope, $element, $attrs);
            };
        }
        function controllerInit(vm, $scope, $element, $attrs) {

            // Controller methods

            vm.toggleTable = toggleTable;
            vm.isToolbarVisible =  isToolbarVisible;
            vm.highlightColumns = highlightColumns;
            vm.unhighlightColumns = unhighlightColumns;

            // Controller properties

            vm.showTable = true;
            vm.showUploadExcelReport = false;
            vm.showNewCsvMethod = false;
            vm.serverSideMode = $attrs.astServerSideMode === 'true';
            vm.searchValue = '';

            $scope.$watch($attrs.astChartingEnabled, function(newVal) {
                vm.chartingIsEnabled = newVal ? true : false;
            });

            // Initialization

            $scope.$watch($attrs.astColumns, function(newVal) {
                vm.columns = newVal;
            });
            $scope.$watch($attrs.astCurrentState, function(newVal) {
                vm.currentState = newVal;
            });
            $scope.$watch($attrs.astCurrency, function(newVal) {
                vm.currency = newVal;
            });
            $scope.$watch($attrs.astExcelQuery, function(newVal) {
                vm.excelQuery = newVal;
            });
            $scope.$watch($attrs.astSetServerSideSearch, function(newVal) {
                vm.setServerSideSearch = newVal;
            });
            if (vm.serverSideMode) {
                $scope.$watch($attrs.astDisplayData, function(newVal) {
                    vm.displayData = newVal;
                });
                $scope.$watch($attrs.astChartData, function(newVal) {
                    vm.chartData = newVal;
                });
                vm.setPageSize = $scope.$eval($attrs.astSetPageSize);
                vm.setPageNumber = $scope.$eval($attrs.astSetPageNumber);
                vm.setSortColumn = $scope.$eval($attrs.astSetSortColumn);
                vm.setFilter = $scope.$eval($attrs.astSetFilter);
                vm.setChartConfig = $scope.$eval($attrs.astSetChartConfig);
                vm.getResultRange = getResultRange;
                vm.getPageRange = getPageRange;
            }
            vm.setFilterGrid = $scope.$eval($attrs.astSetFilterGrid);
            $scope.$watch($attrs.astSetFilterMenu, function(newVal) {
                vm.filterMenuList = newVal;
            });
            $scope.$watch($attrs.astShowUploadExcelReport, function(newVal) {
                vm.showUploadExcelReport = newVal;
            });
            $scope.$watch($attrs.astShowNewCsvMethod, function(newVal) {
                vm.showNewCsvMethod = newVal;
            })
            $scope.$watch($attrs.astShowDownloadExcelReport, function(newVal) {
                vm.showDownLoadExcelReport = newVal;
            });

            vm.setDownloadFilter = $scope.$eval($attrs.astSetDownloadFilter);
            vm.filterMenuTemplate = $scope.$eval($attrs.astSetFilterMenuTemplate);
            vm.setDownloadCallback = $scope.$eval($attrs.astSetDownloadCallback);
            // Public methods

            function toggleTable(){
                vm.showTable = !vm.showTable;
            }
            function isToolbarVisible(){
              var showToolbar = $scope.$eval($attrs.showToolbar);
              return _.isBoolean(showToolbar) ? showToolbar : true;
            }
            function highlightColumns(fieldTypeIds) {
                _applyColumnHighlight(fieldTypeIds, true);
            }
            function unhighlightColumns(fieldTypeIds) {
                _applyColumnHighlight(fieldTypeIds, false);
            }
            function getResultRange() {
                var resultRange = {from: 0, to: 0, total: 0};
                if (!_.isEmpty(vm.currentState)) {
                    if(_.isNumber(_.get(vm.currentState, 'numberOfResults')) &&
                        _.isNumber(_.get(vm.currentState, 'currentPageNumber')) &&
                        _.isNumber(_.get(vm.currentState, 'currentPageSize'))) {
                        if (vm.currentState.numberOfResults > 0) {
                            return {
                                from: (vm.currentState.currentPageNumber - 1) * vm.currentState.currentPageSize + 1,
                                to: Math.min(vm.currentState.currentPageNumber * vm.currentState.currentPageSize, vm.currentState.numberOfResults),
                                total: vm.currentState.numberOfResults
                            };
                        }
                    }
                }
                return resultRange;
            }
            function getPageRange() {
                var range = [];
                if (_isCurrentPageNumberAndNumberOfPages()) {
                    var displayedPages = 5,
                        start = Math.max(1, vm.currentState.currentPageNumber - Math.abs(Math.floor(displayedPages / 2))),
                        end = start + displayedPages - 1;
                    if (end > vm.currentState.numberOfPages) {
                        end = vm.currentState.numberOfPages;
                        start = Math.max(1, end - displayedPages + 1);
                    }
                    for (var i = start; i <= end; i++) {
                        range.push(i);
                    }
                }
                return range;
            }

            // Private functions
            function _isCurrentPageNumberAndNumberOfPages() {
                if (!_.isEmpty(vm.currentState) && _.isObject(vm.currentState)) {
                    return _.isNumber(vm.currentState.currentPageNumber) && _.isNumber(vm.currentState.numberOfPages);
                }
                return false;
            }

            function _applyColumnHighlight(fieldTypeIds, highlighted) {
                if (_.isString(fieldTypeIds)) {
                    _.set(_.find(vm.columns, {fieldTypeId: fieldTypeIds}), 'highlighted', highlighted);
                } else if (_.isArray(fieldTypeIds)) {
                    _.forEach(fieldTypeIds, function(fieldTypeId) {
                        _.set(_.find(vm.columns, {fieldTypeId: fieldTypeId}), 'highlighted', highlighted);
                    });
                } else if (_.isUndefined(fieldTypeIds) && highlighted === false) {
                    _.forEach(vm.columns, function(column) {
                        column.highlighted = highlighted;
                    });
                }
            }
        }
        function link(scope, element, attrs, alphaSmartTableCtrl){
            scope.$watch(attrs.showTable, function(value){
                if(_.isBoolean(value)){
                    alphaSmartTableCtrl.showTable = value;
                }
            });
            scope.$watch(attrs.fixedBody, function(value){
                var alphaTableBody = element.find('.alpha-table-body');
                $timeout(function(){
                    if(_.isBoolean(value) && value){
                        alphaTableBody.css('min-height', alphaTableBody.css('height'));

                    }else if(_.isBoolean(value) && !value){
                        alphaTableBody.css('min-height', 0);
                    }
                },100);
            });
        }
    }
})();
