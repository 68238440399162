(function() {
    'use strict';

    /**
     *
     */
    angular
        .module('alpha.common.directives.alphaDocPreviewPopup', [])
        .directive('alphaDocPreviewPopup', alphaDocPreviewPopup);

    alphaDocPreviewPopup.$inject = [
        'I18nUtil',
        'LoaderService',
        'UserPreferences',
        'SavedSearchService',
        'AlphaDocPreviewDownloadService',
        'Utils',
        'RecordDataUtil'
    ];

    function alphaDocPreviewPopup(
        I18nUtil,
        LoaderService,
        UserPreferences,
        SavedSearchService,
        AlphaDocPreviewDownloadService,
        Utils,
        RecordDataUtil
    ) {
        return {
            restrict: 'E',
            scope: {
                'alphaDocRow': '=',
                'alphaDocColumn': '=',
                'alphaDocRowIndex': '=',
                'alphaDocDisplayName': '=?getDisplayValue',
                'alphaDocDisplayClass': '=?'
            },
            replace: true,
            template: template,
            link: link
        };
        function link(scope, element, attrs) {

            scope.clientId = "";
            scope.allDisplayAbleExtensions = [];

            scope.viewAttachments = viewAttachments;
            scope.downloadFile = downloadFile;
            scope.isPreviewEnabled = isPreviewEnabled;

            UserPreferences.getClientId().then(function(clientId) {
                scope.clientId = clientId;
            });

            AlphaDocPreviewDownloadService.getAllDisplayAbleExtensions().then(function(data) {
                scope.allDisplayAbleExtensions = data;
            });

            function isPreviewEnabled(){
                if (scope.alphaDocColumn && scope.alphaDocRow) {
                    if (!scope.alphaDocColumn.name) {
                        scope.alphaDocColumn.name = scope.alphaDocColumn.fieldName;
                    }
                    var fileType = "Not Supported",
                        dataType = (scope.alphaDocColumn.fieldMetadata) ? scope.alphaDocColumn.fieldMetadata.dataType : scope.alphaDocColumn._fieldType.dataType,
                        widgetType = (scope.alphaDocColumn.fieldMetadata) ? scope.alphaDocColumn.fieldMetadata.widgetType : scope.alphaDocColumn._fieldType.widgetType,
                        row = (scope.alphaDocRow[scope.alphaDocColumn.name]) ? scope.alphaDocRow : scope.alphaDocRow[scope.alphaDocColumn.recordTypeId];
                    if (!(dataType === 'String' && widgetType === 'DocumentLink' && (row && row[scope.alphaDocColumn.name]))) {
                        scope.alphaDocDisplayName = (row && row[scope.alphaDocColumn.name]) ? decodeFileName(row[scope.alphaDocColumn.name][0]['fileName']) : '';
                    } else {
                        scope.alphaDocDisplayName = RecordDataUtil.getDisplayValue(row[scope.alphaDocColumn.name], dataType, widgetType);
                        fileType = AlphaDocPreviewDownloadService.getFileType(row[scope.alphaDocColumn.name]);
                        scope.isDocumentLink = true;
                    }
                    if (dataType === 'Attachment' && (row && row[scope.alphaDocColumn.name])) {
                        fileType = AlphaDocPreviewDownloadService.getFileType(row[scope.alphaDocColumn.name][0]['fileName']);
                    }

                    return AlphaDocPreviewDownloadService.isBrowserNativeFileType(fileType) ? true : _.indexOf(scope.allDisplayAbleExtensions, fileType) > -1;
                }
                return;
            }

            function decodeFileName(fileName){
                var decodedName = fileName;
                try {
                    decodedName = decodeURIComponent(fileName);
                } catch (e) {
                    //Let the original file name be returned.
                }
                return decodedName;
            }

            function downloadFile() {
                if (!scope.alphaDocColumn && !scope.alphaDocRow) { return; }
                var row = (scope.alphaDocRow[scope.alphaDocColumn.name]) ? scope.alphaDocRow : scope.alphaDocRow[scope.alphaDocColumn.recordTypeId];

                if (row[scope.alphaDocColumn.name]) {
                    LoaderService.startLoading();
                    var fileName , fileType , fieldTypeId ,filePath;
                    if(scope.isDocumentLink){
                        fileName = scope.alphaDocDisplayName;
                        fileType = AlphaDocPreviewDownloadService.getFileType(row[scope.alphaDocColumn.name]);
                        fieldTypeId = (scope.alphaDocColumn.fieldTypeId) ? scope.alphaDocColumn.fieldTypeId : scope.alphaDocColumn._fieldType.id;
                        filePath = applicationContextRoot + '/dp/file/' + scope.clientId + '/' + row['_entity_'] + '/' + row['_id'] + '/' + fieldTypeId + '/' + '0';
                    }else{
                        fileName = row[scope.alphaDocColumn.name][0]['fileName'];
                        fileType = AlphaDocPreviewDownloadService.getFileType(row[scope.alphaDocColumn.name][0]['fileName']);
                        fieldTypeId = (scope.alphaDocColumn.fieldTypeId) ? scope.alphaDocColumn.fieldTypeId : scope.alphaDocColumn._fieldType.id;
                        filePath = applicationContextRoot + '/dp/file/' + scope.clientId + '/' + row['_entity_'] + '/' + row['_id'] + '/' + fieldTypeId + '/' + row[scope.alphaDocColumn.name][0]['fileId'];
                    }
                    AlphaDocPreviewDownloadService.getAttachmentFile(filePath, fileType)
                        .then(function (response) {
                            Utils.downloadFile(decodeURIComponent(fileName), response.data);
                        })
                        .catch(function (error) {
                            console.log("ERROR");
                            console.log(error);
                        })
                        .finally(function () {
                            LoaderService.stopLoading();
                        });
                }

            }

            function viewAttachments() {
                if (!scope.alphaDocColumn && !scope.alphaDocRow) { return; }
                var fileType, mimeType = "Not Supported",
                    fileDataUrl, fileBlobData, fileBlobURL,
                    columnName = scope.alphaDocColumn.name,
                    fieldTypeId = (scope.alphaDocColumn.fieldTypeId) ? scope.alphaDocColumn.fieldTypeId : scope.alphaDocColumn._fieldType.id,
                    row = (scope.alphaDocRow[scope.alphaDocColumn.name]) ? scope.alphaDocRow : scope.alphaDocRow[scope.alphaDocColumn.recordTypeId];

                LoaderService.startLoading();

                if (row[columnName]) {
                    if(scope.isDocumentLink){
                        fileType = AlphaDocPreviewDownloadService.getFileType(row[columnName]);
                        mimeType = AlphaDocPreviewDownloadService.getMimeType(fileType);
                        fileDataUrl = applicationContextRoot + '/dp/file/' + scope.clientId + '/' + row['_entity_'] + '/' + row['_id'] + '/' + fieldTypeId + '/' + '0';
                    }else{
                        fileType = AlphaDocPreviewDownloadService.getFileType(row[columnName][0]['fileName']);
                        mimeType = AlphaDocPreviewDownloadService.getMimeType(fileType);
                        fileDataUrl = applicationContextRoot + '/dp/file/' + scope.clientId + '/' + row['_entity_'] + '/' + row['_id'] + '/' + fieldTypeId + '/' + row[columnName][0]['fileId'];
                    }
                }

                if (mimeType !== "Not Supported") {
                    AlphaDocPreviewDownloadService.getAttachmentFilePreview(fileDataUrl, fileType)
                        .then(function (response) {
                            fileBlobData = new Blob([response.data], {type: mimeType});
                            fileBlobURL = window.URL.createObjectURL(fileBlobData);

                            if (mimeType === "image/jpeg") {
                                mimeType = "image/jpg";
                            }

                            element.find('#' + scope.alphaDocRowIndex + '_viewDocFrameObject').attr("data", fileBlobURL);
                            element.find('#' + scope.alphaDocRowIndex + '_viewDocFrameObject').attr("type", mimeType);
                            element.find('#' + scope.alphaDocRowIndex + '_viewAttachmentsModel').modal();

                            setTimeout(function () {
                                angular.element('.modal-backdrop').remove();
                            }, 300);
                        })
                        .catch(function (error) {
                            console.log("ERROR");
                            console.log(error);
                        })
                        .finally(function () {
                            LoaderService.stopLoading();
                        });
                } else {
                    element.find('#' + scope.alphaDocRowIndex + '_viewDocFrameObject').attr("data", "Not Supported!!!");
                    element.find('#' + scope.alphaDocRowIndex + '_viewDocFrameObject').attr("type", "Not Supported");
                    element.find('#' + scope.alphaDocRowIndex + '_viewAttachmentsModel').modal();

                    LoaderService.stopLoading();

                    setTimeout(function () {
                        angular.element('.modal-backdrop').remove();
                    }, 300);
                }

            }
        }

        function template(element, attrs){
            return '<span>' +

                '<a  class=" btn-link btn-view-expand" >'+
                '<span ng-if="isPreviewEnabled()"' +
                'class="view-attachment-preview-icon view-attachment-preview-icon-blue openViewAttachmentsModel" ng-click="viewAttachments()"></span>' +
                '</a>'+
                '<span class="view-attachment-text" ng-click="downloadFile()" ng-class="alphaDocDisplayClass">{{alphaDocDisplayName}}</span>' +
                '<div class="modal fade view-attachment-modal" id="{{alphaDocRowIndex}}_viewAttachmentsModel" tabindex="-1" role="dialog">' +
                '<div class="modal-dialog view-attachment-dialog">' +
                '<div class="modal-content"> ' +
                '<div class="modal-header">' +
                '<button type="button" class="btn btn-default pull-right" data-dismiss="modal" translate="BTN_CLOSE">Close</button>' +
                '<button type="button" id="{{alphaDocRowIndex}}_viewAttachmentDownloadButton" ' +
                'ng-click="downloadFile()"' +
                'class="btn btn-primary pull-left" data-dismiss="modal" translate="BTN_DOWNLOAD">Download</button>' +
                '</div>' +
                '<div class="modal-body" id="{{alphaDocRowIndex}}_viewDocFrameContainer">' +
                '<object id="{{alphaDocRowIndex}}_viewDocFrameObject" class="view-attachment-body"  frameborder="0"> </object>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</span>';
        }
    }
})();