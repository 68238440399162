(function() {
    'use strict';

    angular
        .module('alpha.common.services.navigation', [
            'AlphaApi',
            'UserPreferences',
            'alpha.utils.I18n',
            'alpha.utils.recordData',
            'alpha.common.services.authentication',
            'alpha.common.services.security',
            'alpha.common.services.alphaTabManager'
        ])
        .factory('NavigationService', NavigationService);

    NavigationService.$inject = [
        'DataModelDesignInterface',
        'UserPreferences',
        'I18nUtil',
        'RecordDataUtil',
        'AuthenticationService',
        'SecurityService',
        'AlphaTabManagerService',
        '$q',
        '$http',
        '$window'
    ];

    function NavigationService(
        DataModelDesignInterface,
        UserPreferences,
        I18nUtil,
        RecordDataUtil,
        AuthenticationService,
        SecurityService,
        AlphaTabManagerService,
        $q,
        $http,
        $window
    ) {
        var MODULE_TITLES = {
                businessIntelligence: I18nUtil.getI18nString('TAB_BI', 'Business Intelligence'),
                dataDiscovery: I18nUtil.getI18nString('MENUITEM_DATA_DISCOVERY', 'Data Discovery'),
                dataGovernance: I18nUtil.getI18nString('LBL_DATA_GOVERNANCE', 'Data Governance'),
                digital: I18nUtil.getI18nString('LBL_DIGITAL', 'Digital'),
                exchangeRates: I18nUtil.getI18nString('TAB_EXCHANGE_RATES', 'Exchange Rates'),
                formLetters: I18nUtil.getI18nString('TAB_FORM_LETTERS', 'Form Letters'),
                groupManagement: I18nUtil.getI18nString('TAB_GROUP_MANAGEMENT', 'Group Management'),
                myProfile: I18nUtil.getI18nString('TAB_MY_PROFILE', 'My Profile'),
                quickSearch: I18nUtil.getI18nString('TAB_QUICK_SEARCH', 'Quick Search'),
                recentRecords: I18nUtil.getI18nString('LBL_RECENT_RECORDS', 'Recent Records'),
                reports: I18nUtil.getI18nString('TAB_REPORTS', 'Reports'),
                search: {
                    global: I18nUtil.getI18nString('TAB_GLOBAL_SEARCH', 'Global Search'),
                    map: I18nUtil.getI18nString('TAB_MAP_SEARCH', 'Map Search')
                },
                spreadsheetUpload: I18nUtil.getI18nString('TAB_SPREADSHEET_UPLOAD', 'Spreadsheet Upload'),
                structuredSearch: I18nUtil.getI18nString('TAB_ADVANCED_QUERY', 'Advanced Query'),
                userManagement: I18nUtil.getI18nString('TAB_USER_MANAGEMENT', 'User Management'),
                viewRecord: I18nUtil.getI18nString('LBL_RECORD_DETAIL_PAGE', 'Record Detail Page'),
                workflowAutomation: I18nUtil.getI18nString('TAB_WORKFLOW_AUTOMATION', 'WorkFlow Automation'),
                dataScience: I18nUtil.getI18nString('MENUITEM_DATA_SCICENCE', 'Data Science'),
                changepassword : I18nUtil.getI18nString('TAB_CHANGE_PASSWORD', 'Change Password'),
                DM_lookupLibraries : I18nUtil.getI18nString('LBL_DM_WORKBENCH', 'Data Model Workbench'),
                LBL_WORKBENCH_HOME : I18nUtil.getI18nString('LBL_WORKBENCH_HOME', 'Workbench Home'),
                LBL_UI_WORKBENCH : I18nUtil.getI18nString('LBL_UI_WORKBENCH', 'UI Workbench'),
                LBL_DM_WORKBENCH : I18nUtil.getI18nString('LBL_DM_WORKBENCH', 'Data Model Workbench'),
                LBL_BR_WORKBENCH : I18nUtil.getI18nString('LBL_BR_WORKBENCH', 'Business Rules Workbench'),
                LBL_BO_WORKBENCH : I18nUtil.getI18nString('LBL_BO_WORKBENCH', 'Business Object Workbench'),
                LBL_BI_WORKBENCH : I18nUtil.getI18nString('LBL_BI_WORKBENCH', 'Business Intelligence Workbench'),
                LBL_DATA_INTEGRITY : I18nUtil.getI18nString('LBL_DATA_INTEGRITY', 'Data Integrity'),
                LBL_CLIENT_INFORMATION : I18nUtil.getI18nString('LBL_CLIENT_INFORMATION', 'Client Information')
            },
            MODULE_ICONS = {
                businessIntelligence: 'icon-ic_report',
                dataDiscovery: 'icon-ic_report',
                dataGovernance: 'icon-ic_report',
                digital: 'icon-ic_report',
                exchangeRates: 'icon-ic_report',
                formLetters: 'icon-ic_form_letter',
                groupManagement: 'icon-ic_group_top_mgmt',
                quickSearch: 'icon-ic_history',
                reports: 'icon-ic_report',
                spreadsheetUpload: 'icon-ic_report',
                userManagement: 'icon-ic_user_tab',
                workflowAutomation: 'icon-ic_group_top_mgmt',
                dataScience: 'icon-ic_report'

            },
            MODULE_AUTHORITIES = {
                businessIntelligence: ['ShowAdministrationSetting', 'BusinessIntelligenceAuthority'],
                dataDiscovery: ['AnalyticsAuthority', 'DataDiscoveryAuthority'],
                dataGovernance: ['ShowAdministrationSetting', 'DataGovernanceAuthority'],
                exchangeRates: ['ShowAdministrationSetting', 'RateSchemeAuthority'],
                formLetters: ['ShowAdministrationSetting', 'FormLetterAuthority'],
                groupManagement: ['ShowAdministrationSetting', 'SecurityAssignmentAuthority'],
                quickSearch: ['RecordMenuAuthority'],
                recentRecords: ['RecordMenuAuthority', 'RecentRecordAuthority'],
                reports: ['AnalyticsAuthority', 'ReportAuthority'],
                riskOneTools: {
                    riskOneTools: ['ShowAdministrationSetting', 'DataModelDesignAuthority', 'BusinessRulesWorkbenchAuthority', 'BusinessObjectWorkbenchAuthority', 'UIWorkbenchAuthority'],
                    lookupLibraries: ['ShowAdministrationSetting', 'LookupMaintenanceAuthority']
                },
                search: {
                    global: ['AnalyticsAuthority', 'GlobalSearchAuthority'],
                    map: ['AnalyticsAuthority', 'MapSearchAuthority']
                },
                spreadsheetUpload: ['RecordMenuAuthority', 'SpreadSheetUploadAuthority'],
                structuredSearch: ['AnalyticsAuthority', 'AdvanceQueryAuthority'],
                userManagement: ['ShowAdministrationSetting', 'SecurityAssignmentAuthority'],
                workflowAutomation: ['ShowAdministrationSetting', 'WorkFlowAutomationAuthority'],
                dataScience: ['AnalyticsAuthority', 'DataScienceAuthority'],
            },
            _tabCounter = 0,
            _currentUserAuthorities;

        return {
            openModuleTab: openModuleTab,
            openModuleWindow: openModuleWindow,
            getModuleUrl: getModuleUrl,
            getModuleTitle: getModuleTitle,
            getModuleIcon: getModuleIcon,
            moduleIsAvailable: moduleIsAvailable,
            logOut: logOut
        };

        // Public methods

        /**
         * Opens a module in a new application tab.
         *
         * @method openModuleTab
         *
         * @param {String} name Name of the module
         * @param {String} [id] Tab ID if needed
         * @param {String} [title] Tab title if needed
         * @param {Object} [options] Options for modules that support them
         */
        function openModuleTab(name, id, title, options) {
            var icon = this.getModuleIcon(name);
            if (!id) {
                id = name + '__' + _tabCounter++;
            }
            if (!title) {
                title = this.getModuleTitle(name, options);
            }
            this.getModuleUrl(name, options)
                .then(function(url) {
                    if (name === 'businessIntelligence' && id) {
                        _initBiFrame(id); // It is NOT safe to run this without an ID
                    } else if (name === 'search' && options && options.doNotRunSearch) {
                        sessionStorage.setItem('doNotRunSearch', 1);
                    }
                    AlphaTabManagerService.addTab(id, title, url, icon, name);
                });
        }

        /**
         * Opens a module in a new browser window.
         *
         * @method openModuleWindow
         *
         * @param {String} name Name of the module
         * @param {Object} [options] Options for modules that support them
         */
        function openModuleWindow(name, options) {
            this.getModuleUrl(name, options)
                .then(function(url) {
                    if (name === 'search' && options && options.doNotRunSearch) {
                        sessionStorage.setItem('doNotRunSearch', 1);
                    }
                    AuthenticationService.authenticateUser()
                        .then(function () {
                            if (name === 'riskOneTools') {
                                _openRiskOneTools(url);
                            } else {
                                $window.open(url);
                            }
                        });
                });
            function _openRiskOneTools(url) {
                /* TODO:
                    This logic came from MainController. It was for AL-5620. It isn't clear why so much
                    complexity is needed for such a basic task of opening a window, but I can't look at
                    this due to time constraints. This might cause a memory leak because it's storing a
                    reference to another window. It needs to be looked at. */
                var counter = new Date().getTime();
                if (!_.includes(url, '#')) {
                    url += '#';
                }
                if (!_.endsWith(url, '/')) {
                    url += '/';
                }
                url += counter;
                if (typeof($window.top.window.riskonetoolsWindow) === 'undefined' || $window.top.window.riskonetoolsWindow.closed) {
                    $window.top.window.riskonetoolsWindow = $window.top.window.open(url, 'riskonetools');
                } else {
                    $window.top.window.riskonetoolsWindow.location = url;
                    $window.top.window.riskonetoolsWindow.focus();
                }
            }
        }

        /**
         * Provides a full URL of a module.
         *
         * @method getModuleUrl
         *
         * @param {String} name Name of the module
         * @param {Object} [options] Options for modules that support them
         *
         * @returns {Promise} A promise to be resolved with the URL of the module
         */
        function getModuleUrl(name, options) {
            var deferred = $q.defer();
            switch (name) {
                case 'businessIntelligence':
                    if (options && options.url) {
                        deferred.resolve(options.url);
                    } else {
                        _getBiUrl();
                    }
                    break;
                case 'dataDiscovery':
                    _getBiUrl();
                    break;
                case 'dataScience':
                    _getDataScienceUrl();
                    break;
                case 'digital':
                    _getDigitalUrl();
                    break;
                case 'viewRecord':
                    _getRecordDetailUrl();
                    break;
                default:
                    _getModuleUrl();
            }
            function _getBiUrl() {
                UserPreferences.getClientId()
                    .then(function(clientId) {
                        DataModelDesignInterface.getClientFromCache(
                            clientId,
                            function success(response) {
                                var url = name === 'dataDiscovery' ? response.client.urlBiDataDiscovery : response.client.urlBi;
                                deferred.resolve(url);
                            },
                            function error(response) {
                                deferred.reject(response.data.errorMessage);
                            }
                        );
                    })
                    .catch(function(reason) {
                        deferred.reject(reason);
                    });
            }
            function _getDigitalUrl() {
                DataModelDesignInterface.getDigitalProperties(
                    function success(props) {
                        $http.post(props.hostname + '/auth/irm', {token: props.token})
                            .then(function(response) {
                                var url = options && options.url ? options.url : props.hostname;
                                url += _.includes(url, '?') ? '&' : '?';
                                url += 'irm_token=' + response.data.token;
                                deferred.resolve(url);
                            })
                            .catch(function(response) {
                                deferred.reject(response);
                            });
                    },
                    function error(response) {
                        deferred.reject(response.data.errorMessage);
                    }
                );
            }
            function _getRecordDetailUrl() {
                UserPreferences.getClientId()
                    .then(function(clientId) {
                        var url = RecordDataUtil.getRecordDetailUrl(clientId, options.recordType);
                        deferred.resolve(url);
                    })
                    .catch(function(reason) {
                        deferred.reject(reason);
                    });
            }
            function _getDataScienceUrl() {
                UserPreferences.getClientId()
                    .then(function(clientId) {
                        DataModelDesignInterface.getClientFromCache(
                            clientId,
                            function success(response) {
                                var url =  response.client.dataScienceDomain + response.client.urlDataScience;
                                deferred.resolve(url);
                            },
                            function error(response) {
                                deferred.reject(response.data.errorMessage);
                            }
                        );
                    })
                    .catch(function(reason) {
                        deferred.reject(reason);
                    });
            }
            function _getModuleUrl() {
                var url = applicationContextRoot + '/static/custom/' + name + '/index.html';
                switch (name) {
                    case 'riskOneTools':
                        if (options && options.subModule) {
                            url += '#/' + options.subModule + '/';
                        }
                        if (options && options.subModule === 'editLayout' && options.layoutName) {
                            url += options.layoutName + '/';
                        }
                        break;
                    case 'search':
                        if (options && options.map) {
                            url += '#?searchType=map&searchTerm=*';
                        } else {
                            url += '#?searchType=refineSearch&searchTerm=&searchRecTypes=null';
                        }
                        break;
                    case 'structuredSearch':
                        if (options && options.resultsView) {
                            url += '#/resultsView/' + options.resultsView;
                        }
                        break;
                    case 'quickSearch':
                        if (options && options.queryId) {
                            url += '#/' + options.queryId;
                        }
                        break;
                }
                deferred.resolve(url);
            }
            return deferred.promise;
        }

        /**
         * Provides a localized title of a module.
         *
         * @method getModuleTitle
         *
         * @param {String} name Name of the module
         * @param {Object} [options] Options for modules that support them
         *
         * @returns {String} The title of the module
         */
        function getModuleTitle(name, options) {
            if (name === 'search') {
                return options && options.map ? MODULE_TITLES.search.map : MODULE_TITLES.search.global;
            }
            return MODULE_TITLES[name];
        }

        /**
         * Provides an icon of a module.
         *
         * @method getModuleIcon
         *
         * @param {String} name Name of the module
         *
         * @returns {String} The class of the icon of the module
         */
        function getModuleIcon(name) {
            return MODULE_ICONS[name];
        }

        /**
         * Indicates whether a module can be accessed.
         *
         * @method moduleIsAvailable
         *
         * @param {String} name Name of the module
         * @param {Object} [options] Options for modules that support them
         *
         * @returns {Boolean} Whether the module is available
         */
        function moduleIsAvailable(name, options) {
            var moduleAuthorities;
            if (_currentUserAuthorities) {
                if (name === 'search') {
                    moduleAuthorities = options && options.map ? MODULE_AUTHORITIES.search.map : MODULE_AUTHORITIES.search.global;
                } else if (name === 'riskOneTools') {
                    moduleAuthorities = options && options.subModule ? MODULE_AUTHORITIES.riskOneTools[options.subModule] : MODULE_AUTHORITIES.riskOneTools.riskOneTools;
                } else {
                    moduleAuthorities = MODULE_AUTHORITIES[name];
                }
                return _.difference(moduleAuthorities, _currentUserAuthorities).length === 0;
            } else {
                _currentUserAuthorities = []; // prevent multiple fetches
                SecurityService.getCurrentUserAuthorities()
                    .then(function(authorities) {
                        _currentUserAuthorities = authorities;
                    })
                    .catch(function(reason) {
                        console.error(reason);
                    });
            }
            return false;
        }

        /**
         * Logs the current user out of the application.
         *
         * @method logOut
         */
        function logOut() {
                $window.top.location = applicationContextRoot + '/j_spring_security_logout';
                $window.localStorage.removeItem("logOut");
                $window.localStorage.setItem("logOut","Y");
        }

        function _initBiFrame(id) {
            /* TODO:
                This logic came from MainController. It was for AL-11789. It isn't clear why Cognos
                wouldn't run properly in a basic browser window without help from IRM, but I can't
                look at this due to time constraints. This will cause an infinite loop of timeouts
                if its condition is never met. It needs to be looked at. */

            // this function returns the require function/handler defined inside the Cognos web application engine
            function _getBIRequireHandler() {

                var doc;
                var iframeObj = document.getElementById(id);

                if (iframeObj === null) {
                    return undefined;
                }

                if (iframeObj.contentWindow && iframeObj.contentWindow.require) {
                    return iframeObj.contentWindow.require;
                }

                if (iframeObj.window && iframeObj.window.require) {
                    return iframeObj.window.require;
                }

                if (!doc && iframeObj.contentDocument) {
                    doc = iframeObj.contentDocument.require;
                }

                if (!doc && iframeObj.document) {
                    doc = iframeObj.document.require;
                }

                if (doc && doc.defaultView && doc.defaultView.require) {
                    return doc.defaultView.require;
                }

                if (doc && doc.parentWindow && doc.parentWindow.require) {
                    return doc.parentWindow.require;
                }

                return undefined;
            }

            // this function checks/waits for Cognos web application to be loaded and cognos require to be initialized, and sets the rca window.require to cognos.require
            function _initBIRequireHandler() {
                if (typeof _getBIRequireHandler() !== 'undefined') {
                    window.require = _getBIRequireHandler();
                }
                else {
                    setTimeout(function() { _initBIRequireHandler(); }, 300);
                }
            }

            // this call will trigger the initializaion of the require function/handler
            if (id) {
                _initBIRequireHandler();
            }
        }
    }
})();
