(function () {
    'use strict';

    /**
     * An extension for a description input to configure internationalized text.
     *
     * @method alphaI18nInput
     *
     * @example
     *      HTML:
     *      <alpha-i18n-input
     *          ng-model="someCtrl.someDescription"
     *          i18n-input-id="Unique_ID"
     *          i18n-input-class="some-class"
     *          i18n-default-language="someCtrl.defaultLanguage"
     *          i18n-placeholder="Enter description"
     *          i18n-placement="bottom-right"
     *          i18n-multi-line="true"
     *          i18n-validators="someCtrl.validators"
     *          i18n-required="true"
     *          i18n-disabled="true"></alpha-i18n-input>
     *
     * @param {Object} ng-model Description to bind this widget to; there is no deep watch, so the entire object must be replaced when editing from outside.
     * @param {String} i18n-input-id Unique HTML ID to be placed on the main input and used as a prefix for other components.
     * @param {String} [i18n-input-class] HTML class to be added to the main input.
     * @param {String} [i18n-default-language] Language of the main input. If defined, this directive will use that value. If undefined, this directive will set the value so the outer controller knows which language is shown.
     * @param {String} [i18n-placeholder] Text to display in the main input when there is no user input.
     * @param {String} [i18n-placement] Position of the editor popover. Defaults to left-bottom. Can also be left-top, right-top, or right-bottom.
     * @param {Boolean} [i18n-multi-line] Whether to display a multi-line input (textarea).
     * @param {Object[]} [i18n-validators] Collection of custom validators to be added to the input.
     * @param {String} i18n-validators.name The name of the validator; be sure this won't conflict with any created by the directive.
     * @param {Function} i18n-validators.callback The function to be called for validation.
     * @param {Boolean} [i18n-required] Whether the value is required. Validatior is "required" and is based on the default language.
     * @param {Boolean} [i18n-disabled] Whether user input is disabled.
     */

    angular
        .module('alpha.common.directives.alphaI18nInput', [
            'ui.bootstrap',
            'alpha.directives.ventivSelectValue',
            'alpha.utils.I18n'
        ])
        .directive('alphaI18nInput', alphaI18nInput);

    alphaI18nInput.$inject = [
        'I18nUtil',
        '$timeout',
        '$log'
    ];

    function alphaI18nInput(
        I18nUtil,
        $timeout,
        $log
    ) {
        return {
            restrict: 'E',
            require: 'ngModel',
            link: link,
            template: template,
            scope: {
                i18nInputId: '@',
                i18nInputClass: '@',
                i18nDefaultLanguage: '=?',
                i18nPlacement: '@',
                i18nPlaceholder: '@',
                i18nMultiLine: '<',
                i18nValidators: '<',
                i18nRequired: '<',
                i18nDisabled: '<',
                i18nCopyToRequired:'<'
            }
        };

        function link(scope, element, attrs, ngModelCtrl) {
            var _requiredLanguages = I18nUtil.getRequiredLanguages();

            // Scope methods

            scope.openI18nSelector = openI18nSelector;
            scope.closeI18nSelector = closeI18nSelector;
            scope.toggleEdit18n = toggleEdit18n;
            scope.updateModel = updateModel;
            scope.languageIsRequired = languageIsRequired;
            scope.valueIsPopulated = valueIsPopulated;

            // Scope properties

            scope.columns = _getGridColumns();
            scope.languages = [];
            scope.modelData = {};

            // Initialization

            ngModelCtrl.$formatters.push(_formatter);
            ngModelCtrl.$parsers.unshift(_parser);
            ngModelCtrl.$render = _render;

            _bindDisabledWatcher();
            _setValidators();
            _getLanguages();

            // Public methods

            function openI18nSelector() {
                scope.selectorOpen = true;
            }
            function toggleEdit18n() {
                scope.isEditing = !scope.isEditing;
                if (scope.isEditing) {
                    $timeout(function() {
                        element.find('.alpha-i18n-description-input').first().focus(); // The inputs must be rendered before this can be executed
                    });
                }
            }
            function closeI18nSelector() {
                scope.isEditing = false;
                scope.selectorOpen = false;
                if(scope.i18nCopyToRequired) {
                    _copyToRequired();
                }
            }
            function updateModel() {
                ngModelCtrl.$setViewValue(angular.copy(scope.modelData.description));
                if (_fieldIsRequired() && !_languagesArePopulated(scope.modelData.description, _.without(_requiredLanguages, scope.i18nDefaultLanguage))) {
                    scope.openI18nSelector();
                }
            }
            function languageIsRequired(language) {
                return _fieldIsRequired() && _.includes(_requiredLanguages, language);
            }
            function valueIsPopulated(value) {
                return _.trim(value).length > 0;
            }

            // Private functions

            function _formatter(modelValue) {
                return angular.copy(modelValue);
            }
            function _parser(viewValue) {
                return angular.copy(viewValue);
            }
            function _render() {
                scope.modelData.description = angular.copy(ngModelCtrl.$viewValue);
            }
            function _setValidators() {
                _.forEach(scope.i18nValidators, function(validator) {
                    ngModelCtrl.$validators[validator.name] = validator.callback;
                });
            }
            function _bindDisabledWatcher() {
                scope.$watch('i18nDisabled', function(value) {
                    if (value) {
                        scope.isEditing = false;
                    }
                });
            }
            function _bindRequiredWatcher() {
                scope.$watch('i18nRequired', function(value) {
                    if (value) {
                        _enableRequired();
                    } else {
                        _disableRequired();
                    }
                });
            }
            function _enableRequired() {
                ngModelCtrl.$validators.required = function(modelValue) {
                    return _languagesArePopulated(modelValue);
                };
                ngModelCtrl.$setValidity('required', _languagesArePopulated(ngModelCtrl.$modelValue));
            }
            function _disableRequired() {
                ngModelCtrl.$setValidity('required', true);
                delete ngModelCtrl.$validators.required;
            }
            function _fieldIsRequired() {
                return _.isFunction(ngModelCtrl.$validators.required);
            }
            function _languagesArePopulated(modelValue, languages) {
                var populated = true;
                _.forEach(languages || _requiredLanguages, function(language) {
                    populated = scope.valueIsPopulated(_.get(modelValue, language));
                    return populated;
                });
                return populated;
            }
            function _getLanguages() {
                I18nUtil.getClientLanguages()
                    .then(function(languages) {
                        scope.languages = languages;
                        if (!scope.i18nDefaultLanguage) {
                            scope.i18nDefaultLanguage = _getDefaultLanguage();
                        }
                        _bindRequiredWatcher();
                    })
                    .catch(function(reason) {
                        $log.error(reason);
                    });
            }
            function _getDefaultLanguage() {
                var currentLanguage = _.find(scope.languages, {locale: I18nUtil.getLanguage()}),
                    fallbackLanguage = _.find(scope.languages, {locale: 'en_US'});
                if (currentLanguage) {
                    return currentLanguage.locale;
                } else if (fallbackLanguage) {
                    return fallbackLanguage.locale;
                } else {
                    return _.first(scope.languages).locale;
                }
            }
            function _getGridColumns() {
                return [{
                    id: 'locale',
                    name: 'locale',
                    displayName: I18nUtil.getI18nString('LBL_LANGUAGE', 'Language'),
                    visible: true,
                    isDefaultSort: true,
                    getter: _getLanguageFromRow,
                    sorter: _getLanguageFromRow,
                    width: '25%'
                }, {
                    id:'description',
                    name: 'description',
                    displayName: I18nUtil.getI18nString('LBL_DESCRIPTION', 'Description'),
                    visible: true,
                    getter: _getDescriptionFromRow,
                    sorter: _getDescriptionFromRow,
                    width: '75%'
                }];
                function _getLanguageFromRow(row) {
                    return row.description;
                }
                function _getDescriptionFromRow(row) {
                    return _.get(scope.modelData, 'description.' + row.locale);
                }
            }
            function _copyToRequired(){

                if(!_languagesArePopulated(scope.modelData.description, _requiredLanguages)){

                    var currentLanguage= _.find(scope.languages, {locale: I18nUtil.getLanguage()});
                    if(!valueIsPopulated(scope.modelData['description']['en_US']) && valueIsPopulated(scope.modelData['description'][currentLanguage.locale])){
                        scope.modelData['description']['en_US'] = angular.copy(scope.modelData['description'][currentLanguage.locale]);
                    }
                    else if(valueIsPopulated(scope.modelData['description']['en_US']) && !valueIsPopulated(scope.modelData['description'][currentLanguage.locale])){
                        scope.modelData['description'][currentLanguage.locale] = angular.copy(scope.modelData['description']['en_US']);
                    }
                    else{
                        var foundValue='';
                        _.forEach(scope.languages, function(language) {
                            foundValue = scope.modelData['description'][language.locale]? scope.modelData['description'][language.locale]:'';
                            if(!_.isEmpty(foundValue)){
                                return false;
                            }
                        });
                        if(foundValue){
                            scope.modelData['description']['en_US'] = foundValue;
                            scope.modelData['description'][currentLanguage.locale] = foundValue;
                        }
                    }
                    updateModel();
                }
            }
        }
        function template(element, attrs) {
            return '<div class="alpha-i18n-container" ng-show="i18nDefaultLanguage">' +
                       '<input ng-if="!i18nMultiLine"' +
                           ' id="{{i18nInputId}}"' +
                           ' class="form-control default-input {{i18nInputClass}}"' +
                           ' type="text"' +
                           ' placeholder="{{i18nPlaceholder}}"' +
                           ' ng-model="modelData.description[i18nDefaultLanguage]"' +
                           ' ng-change="updateModel()"' +
                           ' ng-disabled="i18nDisabled">' +
                       '<textarea ng-if="i18nMultiLine"' +
                           ' id="{{i18nInputId}}"' +
                           ' class="form-control default-input {{i18nInputClass}}"' +
                           ' type="text"' +
                           ' placeholder="{{i18nPlaceholder}}"' +
                           ' ng-model="modelData.description[i18nDefaultLanguage]"' +
                           ' ng-change="updateModel()"' +
                           ' ng-disabled="i18nDisabled"></textarea>' +
                       '<div class="alpha-i18n-btn-container">' +
                           '<button id="{{i18nInputId}}__internationalization_btn"' +
                               ' class="alpha-button-reset alpha-i18n-btn"' +
                               ' type="button"' +
                               ' ng-click="openI18nSelector()"' +
                               ' uib-tooltip="{{\'LBL_ADDITIONAL_LANGUAGES\' | translate}}"' +
                               ' tooltip-append-to-body="true"' +
                               ' uib-popover-template="\'' + applicationContextRoot + '/static/custom/common/app/partials/popovers/alphaI18nSelector.html\'"' +
                               ' popover-class="alpha-popover alpha-i18n-popover"' +
                               ' popover-is-open="selectorOpen"' +
                               ' popover-placement="' + (attrs.i18nPlacement ? attrs.i18nPlacement : 'left-bottom') + '">' +
                               ' <span class="ventiv-icon icon-ic_global"></span>' +
                           '</button>' +
                       '</div>' +
                   '</div>';
        }
    }
})();
