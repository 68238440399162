(function () {
    'use strict';

    /**
     * Adds drag and drop capabilities to columns in a Smart Table.
     *
     * @method alphaSmartTableSortable
     *
     * @example
     *      HTML:
     *      <div alpha-smart-table-sortable
     *          columns="tableColumns"></div>
     *
     * @param {Array} columns The column array configuration for the smart table
     */


    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableSortable', alphaSmartTableSortable);

    function alphaSmartTableSortable() {
        return {
            link: link,
            template: template,
            restrict: 'E',
            replace: true,
            transclude: true
        };
        function link(scope) {
            initSortable();
            function initSortable() {
                scope.options = {
                    'ui-floating': true,
                    placeholder: 'alpha-table-heading alpha-smart-table-over',
                    helper: 'clone',
                    handle: '.sortable-handle',
                    opacity: '0.25',
                    forcePlaceholderSize: true,
                    forceHelperSize: true,
                    start: function (e, ui) {
                        var width = ui.item.sortable.model.width;
                        if(parseInt(ui.item.css('marginLeft')) > 0) {
                            ui.placeholder.css({'width': width, 'marginLeft': ui.item.css('marginLeft')});
                        }else {
                            ui.placeholder.css('width', width);
                        }
                        ui.placeholder.html(ui.item.html());
                    },
                    update: function() {
                    },
                    beforeStop: function() {
                    },
                    stop: function (e, ui) {
                        _.forEach(ui.item.sortable.sourceModel, function(value, index){
                            if(_.has(value,'ordinal')){
                                value.ordinal = index;
                            }
                            if(value.locked){
                                value.locked = false;
                            }
                        });
                    }
                };
            }
        }
        function template(element, attrs){
          return '<div ui-sortable="options" ng-model="'+ attrs.columns +'" ng-transclude></div>';
        }
    }
})();

