(function() {
    'use strict';

    /**
     * Helps manage the headings and cells in a Smart Table grid.
     * Can be used as an element when scrolling is not needed.
     *
     * @method alphaSmartTableBody
     *
     * @example
     *      HTML:
     *      <div alpha-smart-table-body
     *          columns="someGridColumns"></div>
     *
     * @param {Object[]} columns The full column configuration for the smart table
     * @param {Number} columns.ordinal The ordinal of each column
     * @param {Number} [columns.locked] Whether each column is locked
     */

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableBody', alphaSmartTableBody);

    alphaSmartTableBody.$inject = ['AlphaSmartTableService'];

    function alphaSmartTableBody(AlphaSmartTableService) {
        return {
            link: link,
            restrict: 'AE',
            scope: true
        };
        function link(scope, element, attrs) {
            var _columns;
            var tableName;

            scope.getCellPositionCss = getCellPositionCss;

            scope.$watch(attrs.columns, function(newVal) {
                _columns = newVal;
            });
            scope.$watch(attrs.tableName, function(newVal){
                tableName = newVal;
            });

            // Public methods

            function getCellPositionCss(targetColumn,columnWidth) {
                var lockedColumnOffset = _getLockedOffset(targetColumn);
                var columnOffset = _getUnlockedOffset(targetColumn);

                if (targetColumn.locked) {
                    return {
                        "width":columnWidth,
                        "left":lockedColumnOffset
                    }
                } else {                    
                    return {
                        "width":columnWidth
                    }                   
                }
            }

            // Private functions

            function _getLockedOffset(targetColumn) {
                var offset = 0;
                offset += _.sum(_.map(_getPrecedingColumns(targetColumn), function(column) {
                    return parseInt(column.width);
                }));
                return offset + 'px';
            }
            function _getUnlockedOffset(targetColumn) {
                var offset = 0;
                if (_getPrecedingColumn(targetColumn).locked) {
                    offset = _.sumBy(_getLockedColumns(), function(column) {
                        return parseInt(column.width);
                    });
                }
                return offset + 'px';
            }
            function _getPrecedingColumns(targetColumn) {
                return _.filter(_columns, function(column) {
                    return column.visible && column.ordinal < targetColumn.ordinal;
                });
            }
            function _getPrecedingColumn(targetColumn) {
                return _.maxBy(_getPrecedingColumns(targetColumn), 'ordinal') || {};
            }
            function _getLockedColumns() {
                return _.filter(_columns, {locked: true, visible: true}) || [];
            }
        }
    }
})();
